import React, { useEffect } from 'react';

import LoaderButton from '../../components/LoaderButton';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import OrderBy from 'lodash/orderBy';
import Find  from 'lodash/find';
import DeepClone  from 'lodash/cloneDeep';

import useCustomForm from '../../components/Form'

import RequiredFormLabel from "../../components/form/RequiredFormLabel";
import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { getDataByKey, saveData, getPagedData } from '../../utils/FetchUtils'

import { useRouter } from "../../components/Router";

export function EditTransferList() {
    const initialData = {
        Id: '',
        DisplayTransferStaff: '',

        DisplayFromVehicleId: '',
        DisplayFromVehicleRegistration: '',

        DisplayToVehicleId: '',
        DisplayToVehicleRegistration: '',

        Comments: ''
    }

    const [data, setData] = React.useState(initialData);
    const [vehicles, setVehicles] = React.useState([]);

    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const router = useRouter();
    const id = React.useRef(undefined);

    const submitForm = React.useCallback(async (values) => {
        setLoading(true);
        setError(null);
        setInfo(null);

        var data = DeepClone(values.data);
        if (!data.DisplayTransferStaff) {
            setLoading(false);   
            setError("Please select a staff member to conduct the transfer.");
            return;
        }

        if (!data.DisplayFromVehicleId || data.DisplayFromVehicleId === "Vehicle...") {
            setLoading(false);   
            setError("Please select a Vehicle to transfer from.");
            return;
        }

        if (!data.DisplayToVehicleId || data.DisplayToVehicleId === "Vehicle...") {
            setLoading(false);   
            setError("Please select a Vehicle to transfer to.");
            return;
        }

        data.Complete = false;
        data.CompletedBy = null;
        data.CompletedDate = null;

        try {
            var saveResponse = await saveData("TransferList", "Id", data);
            if (saveResponse.isError) {
                setLoading(false);   
                setError(saveResponse.error);
                return;
            }

            setLoading(false);
            setInfo("Transfer List has been saved successfully.");

            /* istanbul ignore next */
            setTimeout(()=>window.location.href = "/transferlists/view/" + values.data.Id, 1000);
        } catch (ex) {
            setLoading(false);
            setError("Unable to save Transfer List. The following error was returned: " + ex.message);      
        }
    }, []);

    const fetchVehicleData = React.useCallback(async () => {
        setError(null);
    
        try {
            var allVehicles = await getPagedData("Vehicle", ["Id", "Registration", "IsTrailer"]);
            if (allVehicles.isError){
                setError(allVehicles.error);
                return;
            }
            var orderedVehicles = OrderBy(allVehicles, "Registration");
            setVehicles(orderedVehicles);
            console.log(vehicles);
        } catch (ex) {
            setError(ex.message);
        }
    }, []);

    const {
        values,
        handleChange,
        handleSubmit,
        setValues
        } = useCustomForm({initialValues: data, onSubmit: submitForm});

    useEffect(() => {
        fetchVehicleData();
    }, [fetchVehicleData]);

    useEffect(() => {
        const fetchData = async () => {            
            if (router.query.id === undefined){
                window.location.href = "/";
            }
            else {
                id.current = router.query.id;
            }
        
            setLoading(true);
            setInfo(null);
    
            try {
                var responseData = await getDataByKey("TransferList", "Id", id.current);
                if (responseData.isError) {
                    setError(responseData.error);
                }
                setData({...initialData, ...responseData });

                setLoading(false);
                console.log(data);
            } catch (ex) {
                setError("Unable to load Transfer List information. The following error was returned: " + ex.message);      
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        var selectedVehicle = Find(vehicles, function(item) { return item.Id === values.DisplayFromVehicleId; });
        if (selectedVehicle) {
            setValues({'DisplayFromVehicleRegistration': selectedVehicle.Registration});
        }
    }, [vehicles, values.DisplayFromVehicleId]);

    useEffect(() => {
        var selectedVehicle = Find(vehicles, function(item) { return item.Id === values.DisplayToVehicleId; });
        if (selectedVehicle) {
            setValues({'DisplayToVehicleRegistration': selectedVehicle.Registration});
        }
    }, [vehicles, values.DisplayToVehicleId]);

    return (
        <div>            
            <Form data-testid="TransferForm" onSubmit={handleSubmit} className="create-packing-list">
                <h1>Edit Transfer List</h1>
                <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Transfer List</LoadingAlert>
                <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
                <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>

                <Form.Row>                        
                    <Form.Group as={Col} xs="12" md="4">
                        <RequiredFormLabel>From Vehicle</RequiredFormLabel>
                        <Form.Control data-testid="DisplayFromVehicle" required as="select" name="DisplayFromVehicleId"
                            onChange={handleChange}
                            value={values.DisplayFromVehicleId}>
                            <option>Vehicle...</option>
                            {vehicles.map((item, index) => {
                                return <option data-testid="FromVehicleOption" key={item.Id} value={item.Id}>{item.Registration}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} xs="12" md="4">
                        <RequiredFormLabel>To Vehicle</RequiredFormLabel>
                        <Form.Control data-testid="DisplayToVehicle" required as="select" name="DisplayToVehicleId"
                            onChange={handleChange}
                            value={values.DisplayToVehicleId}>
                            <option>Vehicle...</option>
                            {vehicles.map((item, index) => {
                                return <option data-testid="ToVehicleOption" key={item.Id} value={item.Id}>{item.Registration}</option>
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} xs="12" md="4"> 
                        <RequiredFormLabel>Transfer Staff</RequiredFormLabel>
                        <Form.Control required placeholder="Transfer Staff"
                            name="DisplayTransferStaff"
                            onChange={staff => {
                                handleChange({target: {name: "DisplayTransferStaff", value: staff.target.value.toLowerCase()}});
                            }}
                            value={values.DisplayTransferStaff}/>
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} xs="12" md="6"> 
                        <Form.Label>Comments</Form.Label>
                        <Form.Control placeholder="Comments" 
                            as="textarea" rows="3" 
                            name={"Comments"}
                            onChange={handleChange}
                            value={values.Comments}/>
                    </Form.Group>
                </Form.Row>

                <div className="text-center mb-5">
                    <LoaderButton
                        isLoading={loading}
                        variant="primary" size="lg"
                        type="submit">Save Transfer List</LoaderButton>
                </div>
            </Form>
        </div>
    )
}
