import React from 'react';

import { Draggable } from "react-beautiful-dnd";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import DraggableList from "./DraggableList";

import { v4 as uuidv4 } from 'uuid';
export default function DraggableEquipmentList({equipmentName, equipmentList, setValues, onChange}) {

    const addEquipment = () => {
        var equipment = [...equipmentList];

        equipment.push({
            Id: uuidv4(),
            Name:'',
            Quantity:0,
            Order: equipment.length
        });
        setValues({[equipmentName]: equipment});
    };

    const removeEquipment = (index) => {
        var equipment = [...equipmentList];
        equipment.splice(index, 1);
        setValues({[equipmentName]: equipment});
    };

    return (
        <Form.Group>
            <Button data-testid="AddEquipment" variant="secondary" className="mb-2 float-right" onClick={addEquipment}>Add</Button>
            <table className='table table-striped table-bordered'>
                <thead className='thead-light'>
                    <tr>
                        <th>Equipment</th>
                        <th style={{ width: '15%' }}>Quantity</th>
                        <th style={{ width: '10%' }}></th>
                    </tr>
                </thead>
                <DraggableList itemList={equipmentList} itemName={equipmentName} setValues={setValues}>
                    {(provided, snapshot) => (
                        <tbody {...provided.droppableProps} ref={provided.innerRef}>
                            {equipmentList.map((item, index) => {
                                return (
                                    <Draggable key={item.Id} draggableId={item.Id} index={index}>
                                        {(provided, snapshot) => (
                                        <tr key={index} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <td>
                                                <span className="d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faBars} className="mr-3 ml-1"/>
                                                    <Form.Control required placeholder="Name" 
                                                        name={equipmentName + '[' + index + '].Name'}
                                                        onChange={onChange}
                                                        value={equipmentList[index].Name}/>
                                                </span>
                                            </td>
                                            <td>
                                                <Form.Control required placeholder="Quantity" type="number"
                                                    min="0" 
                                                    name={equipmentName + '[' + index + '].Quantity'}
                                                    onChange={onChange}
                                                    value={equipmentList[index].Quantity}/>
                                            </td>
                                            <td className="align-middle text-center">
                                                <Button data-testid="RemoveEquipment" variant="secondary" size="sm" onClick={()=>removeEquipment(index)}>x</Button>
                                            </td>
                                        </tr>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </tbody>
                    )}
                </DraggableList>
            </table>
        </Form.Group>
    )
}