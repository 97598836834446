import React, { Component } from 'react';

import DataGrid from '../../components/DataGrid';

export class Vehicles extends Component {

    render() {        
        const columns = [
            {
                accessor: 'Id', 
            },
            {
                Header: 'Registration',
                accessor: 'Registration', 
            }
        ];

        return (
            <React.Fragment>
                <h1 id="VehicleTableLabel">Vehicles</h1>
                <DataGrid tableName='Vehicle' primaryKey='Id' columns={columns} allowSort="true" sortColumn="Registration"></DataGrid>
            </React.Fragment>
        );
    }
}
