import React from 'react';

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import Find from 'lodash/find';

export default function DraggableList({itemList, itemName, orderProp = "Order", setValues, children}) {

    const onDragEnd = (result) => {
        /* istanbul ignore if */
        if (!result.destination) {
            return;
        }

        var orderedList;
        if (typeof itemList[Symbol.iterator] === 'function') {
            // the item list is iterable, so it's an array
            orderedList = reorderArray(
                [...itemList],
                result.source.index,
                result.destination.index
            );
        } else {
            // the item list is not iterable, so it's a map
            orderedList = reorderMap(
                {...itemList},
                result.source.index,
                result.destination.index
            );
        }

        setValues({[itemName]: orderedList});
    }

    const reorderArray = (list, startIndex, endIndex) => {
        const result = [...list];
        /* istanbul ignore if */
        if (result.length === 0) return result;
        
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        var orderedList = [];
        for (let i = 0; i < result.length; i++) {
            var item = Find(list, function(x) { return x.Id === result[i].Id; });
            var orderedListItem = {...item};
            orderedListItem[orderProp] = i;
            orderedList.push(orderedListItem);
        }

        return orderedList;
    };

    const reorderMap = (list, startIndex, endIndex) => {
        const keyList = Object.keys(list);
        const [removed] = keyList.splice(startIndex, 1);
        keyList.splice(endIndex, 0, removed);
    
        var orderedList = {};
        for (var i = 0; i < keyList.length; i++) {
            var key = keyList[i];
            orderedList[key] = {...list[key]};
            orderedList[key][orderProp] = i;
        }

        return orderedList;
    };

    return (
        <DragDropContext data-testid="DragDropContext" onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {children}
            </Droppable>
        </DragDropContext>
    )
}