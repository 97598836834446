import React, { useEffect } from 'react';

import EquipmentRequirementCheckbox from '../../components/EquipmentRequirementCheckbox';

import "react-datepicker/dist/react-datepicker.css";

import Collapse from 'react-bootstrap/Collapse'
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import FlexibleHeader from "../../components/FlexibleHeader";
import { LoadingAlert} from '../../components/alerts/Alerts';

import { v4 as uuidv4 } from 'uuid';

import Some from 'lodash/some';

export default function EquipmentList({values, eventItem, handleChange, loadingEquipment, equipment, trailerSelected}) {

    const [categories, setCategories] = React.useState([]);
    const [selectedCategory, setSelectedCategory] = React.useState(null);

    useEffect(() => {
        var cats = ["All"];
        if (equipment) {
            equipment.forEach((equipmentItem) => {
                if (equipmentItem.Categories) {
                    equipmentItem.Categories.forEach((category) => {
                        if (!Some(cats, function(x) {return x === category.name; })) {
                            cats.push(category.name);
                        }
                    });
                }
            });
            setCategories(cats);
        }
        console.log(cats);
    }, [equipment]);

    const setEquipmentRequirement = (event, eventId, equipmentId, name) => {
        event.stopPropagation();
        var events = {...values.Events};
        var eventItem = events[eventId];

        /* istanbul ignore else */
        if (eventItem) {
            var propName = 'Events[' + eventId + '].Equipment[' + equipmentId + '].' + name;
            handleChange({target: {name: propName, value: !eventItem.Equipment[equipmentId][name]}});

            if (name === "AddToTrailer") {
                // set Add To Trailer for all other events for the same equipment
                Object.keys(events).forEach((eventKey) => {
                    /* istanbul ignore else */
                    if (events[eventKey].Equipment[equipmentId]) {
                        propName = 'Events[' + eventKey + '].Equipment[' + equipmentId + '].' + name;
                        handleChange({target: {name: propName, value: !eventItem.Equipment[equipmentId][name]}});
                    }
                });
            }
        }
    };

    const toggleEquipment = (eventKey, item) => {    
        var events = {...values.Events};        
        var equipment = events[eventKey].Equipment[item.Id];
        var listEquipment = {...events[eventKey].Equipment};
        if (!equipment) {
            // add the equipment to the packing list
            listEquipment[item.Id] = {
                Id: item.Id,
                SandbagRequired: false,
                GeneratorRequired: true,
                AddToTrailer: false,
                EventId: eventKey,
                Quantity: 1,
                EquipmentName: item.EquipmentName
            };

            var addToTrailer = false;
            Object.keys(events).forEach((eventKey) => {
                if (events[eventKey].Equipment[item.Id]) {
                    if (events[eventKey].Equipment[item.Id].AddToTrailer) {
                        addToTrailer = true;
                    }
                }
            });

            listEquipment[item.Id].AddToTrailer = addToTrailer;
        } else {
            // remove the equipment from the packing list
            delete listEquipment[item.Id];
        }

        var propName = 'Events[' + eventKey + '].Equipment';
        handleChange({target: {name: propName, value: listEquipment}});

        console.log(equipment);
        console.log(values);
    };

    const collapseAllEquipment = (eventKey) => {
        var events = {...values.Events};
        var shouldCollapse = !events[eventKey].CollapseEquipment;
        handleChange({target: {name: 'Events[' + eventKey + '].CollapseEquipment', value: shouldCollapse}});
    };

    const filterCategory = (category) => {
        setSelectedCategory(category);
    };

    return (
        <React.Fragment>
            <h5 className="form-section equipment-title">
                <FlexibleHeader 
                    middle={<span className="mb-0">Equipment</span>} 
                    right={<Button data-testid="CollapseEquipmentButton" size="sm" variant="link"
                            onClick={()=>collapseAllEquipment(eventItem.Id)}>
                            {!eventItem.CollapseEquipment ? 'Collapse All' : 'Expand All'}
                        </Button>} />
            </h5>

            <LoadingAlert isVisible={loadingEquipment}>Loading Equipment</LoadingAlert>
            <Collapse data-testid="CategoryCollapse" in={!eventItem.CollapseEquipment}>
            <Form.Group>
                    <div className="d-flex category-filter flex-wrap">
                        {categories.map((category) => {
                            return (<h4 data-testid="CategoryFilter" key={category} className={"category-filter-item " + (selectedCategory === category ? "selected": "")} onClick={()=>filterCategory(category)}><Badge pill variant="info">{category}</Badge></h4>)
                        })}
                    </div>
                    <table className='table table-striped table-bordered m-0 equipment-table'>
                        <tbody>
                            {equipment && equipment.map((item) => {
                                return (
                                    (selectedCategory === "All" || Some(item.Categories, function(x) {return x.id === selectedCategory; })) &&
                                        <tr key={item.Id} data-item={item.Id} data-testid="EquipmentRow" 
                                            className={"equipment-item " + (eventItem.Equipment[item.Id] ? "selected": "")}
                                            onClick={()=>toggleEquipment(eventItem.Id, item)}>
                                            <td className="align-middle border-right-0">{item.EquipmentName}</td>
                                            <td className="align-middle border-left-0">
                                                {eventItem.Equipment[item.Id] &&
                                                    <span key={uuidv4()} className="d-flex flex-column flex-xl-row">
                                                        <EquipmentRequirementCheckbox data-testid="SandbagRequiredCheckbox" name="SandbagRequired" label={[<span key={uuidv4()}>Sandbag&nbsp;</span>, <span key={uuidv4()} className="d-md-none">?</span>,<span key={uuidv4()} className="d-none d-md-inline-block">Required?</span>]}
                                                            eventId={eventItem.Id} equipmentId={item.Id} values={values} onChange={setEquipmentRequirement} />

                                                        <EquipmentRequirementCheckbox data-testid="GeneratorRequiredCheckbox" name="GeneratorRequired" label={[<span key={uuidv4()}>Generator&nbsp;</span>, <span key={uuidv4()} className="d-md-none">?</span>,<span key={uuidv4()} className="d-none d-md-inline-block">Required?</span>]}
                                                            eventId={eventItem.Id} equipmentId={item.Id} values={values} onChange={setEquipmentRequirement} />

                                                        <EquipmentRequirementCheckbox data-testid="AddToTrailerCheckbox" name="AddToTrailer" label={[<span key={uuidv4()} className="d-none d-md-inline-block">Add to&nbsp;</span>, "Trailer?"]} disabled={!trailerSelected}
                                                                eventId={eventItem.Id} equipmentId={item.Id} values={values} onChange={setEquipmentRequirement} />
                                                    </span>
                                                }
                                            </td>
                                            <td width="5%" className="align-middle text-center">
                                                <FontAwesomeIcon className="empty-circle" icon={faCircle} fixedWidth size="2x"/>
                                                <FontAwesomeIcon className="checked-circle" icon={faCheckCircle} fixedWidth size="2x"/>
                                            </td>
                                        </tr>
                                );
                            })}
                        </tbody>
                    </table>
            </Form.Group>
            </Collapse>
        </React.Fragment>
    )
}
