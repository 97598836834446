import React, { Component } from 'react';

import DataGrid from '../../components/DataGrid';

export class Equipment extends Component {
    render() {        
        const columns = [
            {
                accessor: 'Id', 
            },
            {
                Header: 'Name',
                accessor: 'EquipmentName', 
            },
            {
                Header: 'Category',
                accessor: (row) => {
                    if (row.Categories){
                        return row.Categories.map(function(cat) {
                            return cat.name;
                        }).join(", ");
                    }
                    return null;
                }
            }
        ];

        return (
            <React.Fragment>
                <h1 id="EquipmentTableLabel">Equipment</h1>
                <DataGrid tableName='Equipment' primaryKey='Id' columns={columns} allowSort="true" sortColumn="EquipmentName"></DataGrid>
            </React.Fragment>
        );
    }
}
