import React, { useEffect } from 'react';

import LoaderButton from '../../components/LoaderButton';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { EquipmentItemRows} from '../../components/EquipmentItemRow';
import EquipmentTitleRow from '../../components/EquipmentTitleRow';
import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { useRouter } from "../../components/Router";
import { getDataByKey, saveData } from '../../utils/FetchUtils';
import { getDateKey } from '../../utils/DateUtils';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Filter from 'lodash/filter';
import OrderBy from 'lodash/orderBy';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

export function VehicleKit() {
    const initialData = {
        Id: uuidv4(),
        VehicleId: '',
        VehicleRegistration: '',
        Vehicle: {
            Registration: '',
            LastCheckedDate: '',
            VehicleKit: {
                Pegs: {Id: uuidv4(), Name: "Pegs", Quantity: 0},
                TenAmpLeads: {Id: uuidv4(), Name: "10 Amp Leads", Quantity: 0},
                Hoses: {Id: uuidv4(), Name: "Hoses", Quantity: 0},
                OneHPBlowers: {Id: uuidv4(), Name: "1.5HP Blowers", Quantity: 0},
                TwoHPBlowers: {Id: uuidv4(), Name: "2HP Blowers", Quantity: 0}
            },
            ExtraEquipmentKit: {},
            CabinEquipmentKit: {}
        }
    }

    const [data, setData] = React.useState(initialData);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const [orderedEquipment, setOrderedEquipment] = React.useState([]);
    const [orderedExtraEquipment, setOrderedExtraEquipment] = React.useState([]);
    const [orderedCabinEquipment, setOrderedCabinEquipment] = React.useState([]);

    const router = useRouter();
    const id = React.useRef(undefined);

    useEffect(() => {
        const fetchData = async () => {
            if (router.query.id === undefined){
                window.location.href = "/";
            }
            else {
                id.current = router.query.id;
            }
        
            setLoading(true);
            setError(null);
            setInfo(null);
    
            try {
                var vehicleDetails = await getDataByKey("Vehicle", "Id", id.current);
                if (vehicleDetails === null){
                    setError("Unable to load details for Vehicle " + id.current);
                    setLoading(false);   
                    return;
                }
                data.Vehicle = {...data.Vehicle, ...vehicleDetails};
                data.VehicleRegistration = vehicleDetails.Registration;
                data.VehicleId = id.current;

                Object.keys(data.Vehicle.VehicleKit).forEach((key) => {
                    data.Vehicle.VehicleKit[key].Quantity = 0;
                });

                data.Vehicle.VehicleKit.Pegs.Quantity += Number(vehicleDetails.Pegs);
                data.Vehicle.VehicleKit.TenAmpLeads.Quantity += Number(vehicleDetails.TenAmpLeads);
                data.Vehicle.VehicleKit.Hoses.Quantity += Number(vehicleDetails.Hoses);        
                data.Vehicle.VehicleKit.OneHPBlowers.Quantity += Number(vehicleDetails.OneHPBlowers);
                data.Vehicle.VehicleKit.TwoHPBlowers.Quantity += Number(vehicleDetails.TwoHPBlowers);

                convertEquipment(data.Vehicle.ExtraEquipment, data.Vehicle.ExtraEquipmentKit, setOrderedExtraEquipment);
                convertEquipment(data.Vehicle.CabinEquipment, data.Vehicle.CabinEquipmentKit, setOrderedCabinEquipment);

                var orderedVehicleKit = OrderBy(data.Vehicle.VehicleKit, "Name");
                setOrderedEquipment(orderedVehicleKit);
                
                setData({...initialData, ...data});
                setLoading(false);
            } catch (ex) {
                setError("Unable to load Packing List information. The following error was returned: " + ex.message);      
            }
        }

        fetchData();
    }, []);

    const convertEquipment = (equipmentList, equipmentKitList, setEquipment) => {
        /* istanbul ignore else */
        if (equipmentList) {
            equipmentList.forEach(extras => {
                if (!equipmentKitList[extras.Name]) {
                    equipmentKitList[extras.Name] = {
                        Id: uuidv4(),
                        Name: extras.Name,
                        Quantity: 0
                    };
                }
                equipmentKitList[extras.Name].Order = extras.Order;
                equipmentKitList[extras.Name].Quantity += Number(extras.Quantity);
            });

            var orderedEquipmentKit = OrderBy(equipmentKitList, "Order");
            setEquipment(orderedEquipmentKit);
        }
    };

    const completeVehicleKit = async () => {
        var unSelectedMainEquipment = Filter(data.Vehicle.VehicleKit, function(x) { return !x.selected && x.Quantity > 0; });
        var unSelectedExtraEquipment = Filter(data.Vehicle.ExtraEquipmentKit, function(x) { return !x.selected && x.Quantity > 0; });
        var unSelectedCabinEquipment = Filter(data.Vehicle.CabinEquipmentKit, function(x) { return !x.selected && x.Quantity > 0; });
        var unSelectedEquipment = [...unSelectedMainEquipment, ...unSelectedExtraEquipment, ...unSelectedCabinEquipment];
        if (unSelectedEquipment.length > 0) {
            var sortedUnSelectedEquipment = OrderBy(unSelectedEquipment, "Name");
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='delete-item'>
                            <h1>Unchecked Equipment?</h1>
                            <p>The following equipment has not been checked, are you sure you want to save this Kit?</p>
                            <ul className="text-left">
                                {sortedUnSelectedEquipment.map(item => {
                                    return (<li key={uuidv4()} data-testid="UnSelectedEquipment">{item.Name}</li>)
                                })}
                            </ul>
                            <Button variant="secondary" onClick={onClose}>Don't Save</Button>
                            <Button data-testid="SaveKitButton" variant="success"
                                onClick={async () => {
                                    onClose();
                                    await saveVehicleKit();
                                }}>Save</Button>
                        </div>
                    );
                }
            });
        } else {
            await saveVehicleKit();
        }
    };

    const saveVehicleKit = async () => {
        setSaving(true);
        setError(null);
        setInfo(null);
        
        try {
            data.CheckedDate = new Date();
            data.CheckedDateKey = getDateKey(new Date(data.CheckedDate));

            var saveResponse = await saveData("VehicleKit", "Id", data);
            if (saveResponse.isError){
                setError(saveResponse.error);
                setSaving(false);
                return;
            }

            var vehicleSaveResponse = await saveData("Vehicle", "Id", {Id: data.VehicleId, LastCheckedDate: data.CheckedDate});
            if (vehicleSaveResponse.isError){
                setError(vehicleSaveResponse.error);
                setSaving(false);
                return;
            }

            setSaving(false);
            setInfo("Vehicle Kit has been saved successfully.");
            
            /* istanbul ignore next */
            setTimeout(()=>window.location.href = "/", 1000);
        } catch (ex) {
            setSaving(false);
            setError("Unable to save Vehicle Kit information. The following error was returned: " + ex.message);      
        }
    };

    return (
        <div className="packing-list">
            <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Vehicle Kit</LoadingAlert>
            <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
            <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>

            {!loading && 
            <React.Fragment>
                <Row className="pb-3">
                    <Col xs="12" sm="9">
                        <h1>Vehicle Kit</h1>
                        <h2 data-testid="RegistrationLabel">{data.Vehicle.Registration}</h2>
                    </Col>
                    <Col xs="12" sm="3" className="text-sm-right mt-2">
                        <LoaderButton data-testid="CompleteButton" isLoading={saving} variant="primary" onClick={completeVehicleKit}>Complete</LoaderButton>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <table className='table table-striped table-bordered equipment-table'>
                            <tbody>
                                <EquipmentTitleRow>
                                    Vehicle Kit (Last Checked {data.Vehicle.LastCheckedDate ? moment(data.Vehicle.LastCheckedDate).format("ddd DD/MM/YYYY hh:mm:ss A") : "Never"})
                                </EquipmentTitleRow>
                                
                                <InfoAlert isVisible={Object.keys(data.Vehicle.VehicleKit).length === 0} cssClass="m-0">No equipment found for this Vehicle.</InfoAlert>
                                
                                <EquipmentItemRows equipmentList={orderedEquipment} setData={()=> setData({...data})}></EquipmentItemRows>

                                {orderedExtraEquipment.length > 0 && 
                                    <React.Fragment>
                                        <EquipmentTitleRow>Extra Kit</EquipmentTitleRow>
                                        <EquipmentItemRows equipmentList={orderedExtraEquipment} setData={()=> setData({...data})}></EquipmentItemRows>
                                    </React.Fragment>
                                }

                                {orderedCabinEquipment.length > 0 && 
                                    <React.Fragment>
                                        <EquipmentTitleRow>Cabin Equipment</EquipmentTitleRow>
                                        <EquipmentItemRows equipmentList={orderedCabinEquipment} setData={()=> setData({...data})}></EquipmentItemRows>
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                    </Col>                
                </Row>
            </React.Fragment>}
        </div>     
    )
}
