import React, { useEffect } from 'react';

import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import Filter from 'lodash/filter';
import GroupBy from 'lodash/groupBy';

import moment from 'moment';

import { LoadingAlert, ErrorAlert } from '../../components/alerts/Alerts';
import { queryData } from '../../utils/FetchUtils';
import { getDateWithoutTime } from '../../utils/DateUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

import { useRouter } from "../../components/Router";
export function ListTransfers() {

    const [data, setData] = React.useState([]);
    
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");

    const router = useRouter();
    const transferstaff = React.useRef(undefined);
    const vehicleid = React.useRef(undefined);
    const transferdate = React.useRef(undefined);

    useEffect(() => {
        const fetchData = async () => {
            transferstaff.current = router.query.transferstaff;
            vehicleid.current = router.query.vehicleid;
            transferdate.current = router.query.transferdate;
        
            setLoading(true);
            setError(null);
    
            try {
                var queryFilter = "DisplayTransferStaff = :staffName";

                var dateFilter = getDateWithoutTime().toISOString();
                if (!transferdate.current) {
                    queryFilter += " AND TransferDate >= :dateValue"
                } else {
                    queryFilter += " AND TransferDate = :dateValue"
                    dateFilter = transferdate.current;
                }

                var responseData = await queryData("TransferList", "TransferStaffIndex", queryFilter, null, 
                    {"staffName": transferstaff.current, "dateValue": dateFilter}, 
                    ["Id", "TransferDate", "DisplayFromVehicleId", "DisplayFromVehicleRegistration", "DisplayToVehicleRegistration", "DisplayTransferStaff", "Equipment", "ToPackingListId", "FromPackingListId", "Comments"]);

                if (!responseData) {
                    setLoading(false);
                    setError("Unable to find a Transfer for staff member " + transferstaff.current);
                    return;
                }
                else if (responseData.isError) {
                    setLoading(false);
                    setError(responseData.error);
                    return;
                }

                if (vehicleid.current) {
                    responseData = Filter(responseData, function(item) { 
                        return item.DisplayFromVehicleId === vehicleid.current;
                    });
                }

                if (responseData.length > 0) {
                    var groupedLists = GroupBy(responseData, function (item) {
                        return item.TransferDate;
                    });

                    setData(groupedLists);
                } else {
                    setData([]);
                }
                setLoading(false);
                console.log(groupedLists);
            } catch (ex) {
                setError("Unable to load Transfer List information. The following error was returned: " + ex.message);      
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    return (
        <div className="packing-list">
            <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Transfer Lists</LoadingAlert>
            <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>

            {!loading && 
            <React.Fragment>
            <Row className="pb-3">
                <Col xs="12">
                    <h1>Upcoming Transfer Lists for {transferstaff.current}</h1>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col xs="12">
                    <ListGroup className="list-group-flush packing-list-group">                            
                        {(data.length === 0) && <ListGroupItem data-testid="NoTransfersAlert" className="list-group-item-info text-center">No Transfers found.</ListGroupItem>}
                        {Object.keys(data).map((transferDate) => {
                            return (
                                <span key={uuidv4()}>
                                    <a href={"/transferlists/list/?transferstaff=" + transferstaff.current + "&transferdate=" + transferDate} className="packinglist-date border-0 list-group-item list-group-item-action">
                                        <strong>{moment(transferDate).format("dddd D MMM YYYY")}</strong>
                                    </a>
                                    {data[transferDate].map((item, index) => {
                                        return (
                                        <ListGroupItem data-testid="TransferItem" key={uuidv4()} className="border d-flex justify-content-between align-items-center packinglist-item">
                                            <span className="d-flex flex-column mr-auto align-self-start">
                                                <h5 className="d-flex justify-content-start align-items-center flex-grow-1">
                                                    {item.DisplayFromVehicleRegistration}
                                                    {(item.DisplayToVehicleRegistration && item.DisplayFromVehicleRegistration !== item.DisplayToVehicleRegistration) && <FontAwesomeIcon icon={faArrowRight} className="mx-lg-5"/>}
                                                    {item.DisplayFromVehicleRegistration !== item.DisplayToVehicleRegistration && item.DisplayToVehicleRegistration}
                                                </h5>
                                                <ul className="flex-grow-1 mb-0">
                                                    {Object.keys(item.Equipment).map((equipmentKey) => {
                                                        return (
                                                            <li data-testid="TransferEquipment" key={item.Equipment[equipmentKey].Id}>
                                                                {item.Equipment[equipmentKey].EquipmentName}
                                                                {item.Equipment[equipmentKey].UnloadEquipment && <Badge data-testid="UnloadBadge" className="ml-1 mr-1" variant="info">Unload</Badge>}
                                                                {item.Equipment[equipmentKey].KeepEquipment && <Badge data-testid="KeepBadge" className="ml-1 mr-1" variant="success">Keep</Badge>}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </span>
                                            {item.Comments && <span className="align-self-start mr-auto"><h5>Comments</h5><span style={{whiteSpace: 'pre-wrap'}}>{item.Comments}</span></span>}
                                            <h6 className="align-items-end align-self-start d-flex flex-column">
                                                <a href={"/transferlists/view/" + item.Id} className="mb-4">View Transfer List <FontAwesomeIcon icon={faChevronRight} className="d-none d-md-inline-block"/></a>
                                                {item.FromPackingListId && <a data-testid="FromPackingListLink" href={"/packinglists/view/" + (item.FromPackingListId)} className="mb-4">View From Packing List <FontAwesomeIcon icon={faChevronRight} className="d-none d-md-inline-block"/></a>}
                                                {item.ToPackingListId && <a data-testid="ToPackingListLink" href={"/packinglists/view/" + (item.ToPackingListId)}>View To Packing List <FontAwesomeIcon icon={faChevronRight} className="d-none d-md-inline-block"/></a>}
                                            </h6>
                                        </ListGroupItem>
                                        );
                                    })}
                                </span>
                            );
                        })}
                    </ListGroup>
                </Col>
            </Row>
            </React.Fragment>}
        </div>     
    )
}
