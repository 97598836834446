import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

export default function CheckIndicator() {
    return (
        <React.Fragment>
            <FontAwesomeIcon className="empty-circle" icon={faCircle} fixedWidth size="2x"/>
            <FontAwesomeIcon className="checked-circle" icon={faCheckCircle} fixedWidth size="2x"/>
        </React.Fragment>
    );
}