/* istanbul ignore file */

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Auth, Hub } from 'aws-amplify'

Hub.listen(/.*/, ({ channel, payload }) =>
  console.debug(`[hub::${channel}::${payload.event}]`, payload)
)
//Amplify.Logger.LOG_LEVEL = 'DEBUG'

Auth.configure({
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
        domain: process.env.REACT_APP_COGNITO_ENDPOINT,
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: process.env.REACT_APP_SIGN_IN_REDIRECT,
        redirectSignOut: process.env.REACT_APP_SIGN_OUT_REDIRECT,
        responseType: 'code'
    }
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
