import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ErrorAlert } from '../../components/alerts/Alerts';

import UpcomingTransferLists from '../Home/UpcomingTransferLists';

export function Transfers() {

    const [error, setError] = React.useState("");

    return (
        <Row>
            <Col xs="12" className="mb-3">
                <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
                <h1>Transfer Lists</h1>
            </Col>
            <Col xs="12" sm="4" className="mb-3">
                <UpcomingTransferLists setError={setError} />
            </Col>
        </Row>
    );
}
