import React, { useEffect } from 'react';

import LoaderButton from '../../components/LoaderButton';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import RequiredFormLabel from "../../components/form/RequiredFormLabel";
import DraggableEquipmentList from "../../components/DraggableEquipmentList";
import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { useRouter } from "../../components/Router";
import useCustomForm from '../../components/Form'
import {getDataByKey, queryData, saveData} from '../../utils/FetchUtils'

import { v4 as uuidv4 } from 'uuid';

export function EditVehicles() {
    
    const initialData = {
        Id: uuidv4(),
        Registration:'',
        AllowTrailer:false,
        IsTrailer:0,
        Pegs: 0,
        TenAmpLeads: 0,
        Hoses: 0,
        OneHPBlowers: 0,
        TwoHPBlowers: 0,
        ExtraEquipment: [],
        CabinEquipment: [],
        CreatedDate: new Date()
    }

    const [data, setData] = React.useState(initialData);

    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const router = useRouter();
    const id = React.useRef(undefined);

    const submitForm = async (values) => {
        if (!values.data.Registration)
        {
            setError("Please enter a Registration.");
            return;
        }

        setLoading(true);
        setError(null);
        setInfo(null);
    
        try {
            if (id.current !== values.data.Id){
                // this is a new record, or the ID has changed, so we need to check if this ID already exists
                var existing = await queryData("Vehicle", "RegistrationIndex", "Registration = :regValue", null, {"regValue": values.data.Registration});                
                if (existing.length !== 0){
                    if (existing.isError){
                        setError(existing.error);
                    } else {
                        setError("A Vehicle with the same registration (" + values.data.Registration + ") already exists.");   
                    }
                    setLoading(false);   
                    return;
                }
            }

            var saveResponse = await saveData("Vehicle", "Id", values.data);
            if (saveResponse.isError){
                setError(saveResponse.error);
                return;
            }

            setLoading(false);
            id.current = values.data.Registration;

            setInfo("Vehicle " + values.data["Registration"] + " has been saved successfully.");
            console.log(data);
        } catch (ex) {
            setLoading(false);
            setError("Unable to save Vehicle information. The following error was returned: " + ex.message);      
        }
    }

    useEffect(() => {    
        const fetchData = async () => {        
            if (router.query.id !== undefined) {
                id.current = router.query.id;
            }
            else {
                return;
            }
        
            setLoading(true);
            setError(null);
            setInfo(null);
    
            try {
                var responseData = await getDataByKey("Vehicle", "Id", id.current);
                if (responseData.isError){
                    setError(responseData.error);
                }
                setData({...initialData, ...responseData });
                setLoading(false);
                console.log(data);
            } catch (ex) {
                setError("Unable to load Vehicle information. The following error was returned: " + ex.message);      
            }
        }

        fetchData();
    }, []);

    const {
        values,
        handleChange,
        handleSubmit,
        setValues
        } = useCustomForm({initialValues: data, onSubmit: submitForm});

    return (
        <div>            
            <Form onSubmit={handleSubmit} data-testid="VehicleForm">
                <h1><a href="\vehicles"><FontAwesomeIcon icon={faChevronLeft} className="mr-2" /></a>Edit Vehicle</h1>
                <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Vehicle</LoadingAlert>
                <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
                <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>
                {!loading && 
                <React.Fragment>
                    <Form.Row className="align-items-center">
                        <Form.Group as={Col} xs="12" md="6"> 
                            <RequiredFormLabel>Registration</RequiredFormLabel>
                            <Form.Control required placeholder="Registration" 
                                name="Registration"                        
                                onChange={handleChange}
                                value={values.Registration}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Check inline type="checkbox" label="Can Use Trailer?" id="AllowTrailerCheckbox"
                                name="AllowTrailer" data-testid="AllowTrailerCheckbox"
                                onChange={handleChange} 
                                disabled={values.IsTrailer}
                                checked={values.AllowTrailer} />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Check inline type="checkbox" label="Is Trailer?" id="IsTrailerCheckbox"
                                name="IsTrailer" data-testid="IsTrailerCheckbox"
                                onChange={() => handleChange({target: {name: "IsTrailer", value: values.IsTrailer ? 0 : 1}})}
                                disabled={values.AllowTrailer}
                                checked={values.IsTrailer} />
                        </Form.Group>
                    </Form.Row>
                    <h5 className="form-section">Equipment</h5>
                    <Form.Row>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>Pegs</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="Pegs"  
                                onChange={handleChange}
                                value={values.Pegs}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>10 Amp Leads</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="TenAmpLeads"  
                                onChange={handleChange}
                                value={values.TenAmpLeads}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>Hoses</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="Hoses"  
                                onChange={handleChange}
                                value={values.Hoses}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs="12" md="6">
                            <Form.Label>1.5 HP Blowers</Form.Label>
                            <Form.Control type="number"
                                min="0" 
                                name="OneHPBlowers"  
                                onChange={handleChange}
                                value={values.OneHPBlowers} />
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="6">
                            <Form.Label>2 HP Blowers</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="TwoHPBlowers"  
                                onChange={handleChange}
                                value={values.TwoHPBlowers}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Col xs="12" md="6">
                            <h5 className="form-section">Extra Equipment</h5>
                            <DraggableEquipmentList equipmentName="ExtraEquipment" equipmentList={values.ExtraEquipment} setValues={setValues} onChange={handleChange} />
                        </Col>
                        <Col xs="12" md="6">
                            <h5 className="form-section">Cabin Equipment</h5>
                            <DraggableEquipmentList equipmentName="CabinEquipment" equipmentList={values.CabinEquipment} setValues={setValues} onChange={handleChange} />
                        </Col>
                    </Form.Row>

                    <LoaderButton
                        isLoading={loading}
                        variant="primary"
                        type="submit">Save</LoaderButton>
                </React.Fragment>}
            </Form>
        </div>
    )

}
