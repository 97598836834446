import React, { useEffect } from 'react';

import LoaderButton from '../../components/LoaderButton';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import RequiredFormLabel from "../../components/form/RequiredFormLabel";
import DraggableEquipmentList from "../../components/DraggableEquipmentList";
import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { useRouter } from "../../components/Router";
import useCustomForm from '../../components/Form';
import {getDataByKey, queryData, saveData} from '../../utils/FetchUtils';

import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';

import ReactTags from 'react-tag-autocomplete'

import Find  from 'lodash/find';

export function EditEquipment() {
    const initialData = {
        Id: uuidv4(),
        EquipmentName:'',
        Categories:[],
        Pegs: 0,
        Sandbags: 0,
        TenAmpLeads: 0,
        Hoses: 0,
        OneHPBlowers: 0,
        TwoHPBlowers: 0,
        ExtraEquipment: [],
        CreatedDate: new Date()
    }

    const categories = [
        { id: 'Active Games', name: 'Active Games' },
        { id: 'Bouncy Castle', name: 'Bouncy Castle' },
        { id: 'Bucking Bull', name: 'Bucking Bull' },
        { id: 'Climbing Activities', name: 'Climbing Activities' },
        { id: 'Dunk Tanks', name: 'Dunk Tanks' },
        { id: 'Games', name: 'Games' },
        { id: 'Inflatable Furniture', name: 'Inflatable Furniture' },
        { id: 'Obstacle Course', name: 'Obstacle Course' },
        { id: 'Slides', name: 'Slides' },
        { id: 'Spider Mountain', name: 'Spider Mountain' },
        { id: 'Sport Inflatable', name: 'Sport Inflatable' },
        { id: 'Sumo/Super Hero', name: 'Sumo/Super Hero' },
        { id: 'Miscellaneous', name: 'Miscellaneous' },
        { id: 'Water Slide', name: 'Water Slide' },
        { id: 'Zorb/Bubble Soccer', name: 'Zorb/Bubble Soccer' }
    ];

    const [data, setData] = React.useState(initialData);
    const [lastPackingList, setLastPackingList] = React.useState(false);
    const [lastPackingListData, setLastPackingListData] = React.useState({
        PackingListId: '',
        VehicleId: '',
        VehicleRegistration: '',
        LoadingDate: ''
    });

    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const router = useRouter();
    const id = React.useRef(undefined);

    const submitForm = React.useCallback(async (values) => {
        if (!values.data.EquipmentName)
        {
            setError("Please enter a name.");
            return;
        }

        if (!values.data.Categories || values.data.Categories.length === 0)
        {
            setError("Please select a Category.");
            return;
        }

        setLoading(true);
        setError(null);
        setInfo(null);
    
        try {      
            if (id.current !== values.data.Id){
                // this is a new record, or the ID has changed, so we need to check if this ID already exists
                var existing = await queryData("Equipment", "NameIndex", "EquipmentName = :nameValue", null, {"nameValue": values.data.EquipmentName});
                if (existing.length !== 0){
                    if (existing.isError){
                        setError(existing.error);
                    } else {
                        setError("A Equipment with the same name (" + values.data.EquipmentName + ") already exists.");   
                    }
                    setLoading(false);   
                    return;
                }
            }
            
            var saveResponse = await saveData("Equipment", "Id", values.data);
            if (saveResponse.isError){
                setError(saveResponse.error);
                return;
            }
            setLoading(false);
            setInfo("Equipment " + values.data["EquipmentName"] + " has been saved successfully.");
            console.log(data);
        } catch (ex) {
            setLoading(false);
            setError("Unable to save Equipment information. The following error was returned: " + ex.message);      
        }
    }, [data])

    useEffect(() => {
        const fetchData = async () => {
            if (router.query.id !== undefined) {
                id.current = router.query.id;
            }
            else {
                return;
            }
        
            setLoading(true);
            setError(null);
            setInfo(null);
    
            try {
                var responseData = await getDataByKey("Equipment", "Id", id.current);
                if (responseData.isError){
                    setError(responseData.error);
                }

                if ((!responseData.Categories || responseData.Categories.length === 0) && responseData.Category) {
                    responseData.Categories = [{ id: responseData.Category, name: responseData.Category }];
                }
                setData({...initialData, ...responseData });

                var lastPackingList = await queryData("PackingListEquipment", "EquipmentIndex", "EquipmentId = :idValue AND LoadingDate < :dateValue", null, 
                    {"idValue": id.current, "dateValue": new Date().toISOString()}, ["PackingListId", "LoadingDate", "VehicleId"], 1, false);

                if (lastPackingList && lastPackingList.length === 1) {
                    var lastPackingListVehicle = await getDataByKey("Vehicle", "Id", lastPackingList[0].VehicleId, ["Registration"]);
                    setLastPackingListData({
                        PackingListId: lastPackingList[0].PackingListId,
                        VehicleId: lastPackingList[0].VehicleId,
                        VehicleRegistration: lastPackingListVehicle.Registration,
                        LoadingDate: lastPackingList[0].LoadingDate
                    });
                    setLastPackingList(true);
                }
                setLoading(false);
                console.log(data);
            } catch (ex) {
                setError("Unable to load Equipment information. The following error was returned: " + ex.message);      
            }
        }

        fetchData();
    }, []);

    const {
        values,
        handleChange,
        handleSubmit,
        setValues
        } = useCustomForm({initialValues: data, onSubmit: submitForm});


    const deleteCategory = (index) => {
        const cats = values.Categories.slice(0);
        cats.splice(index, 1);
        handleChange({target: {name: "Categories", value: cats}});
    };
    
    const addCategory = async (category) => {
        if (!Find(values.Categories, function(x) { return x.id === category.id; })) {
            const cats = [].concat(values.Categories, category)
            handleChange({target: {name: "Categories", value: cats}});
        }
    };

    return (
        <div>            
            <Form onSubmit={handleSubmit} data-testid="EquipmentForm">
                <h1><a href="\equipment"><FontAwesomeIcon icon={faChevronLeft} className="mr-2" /></a>Edit Equipment</h1>
                <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Equipment</LoadingAlert>
                <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
                <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>
                {!loading && 
                <React.Fragment>
                    <InfoAlert data-testid="LastPackingListAlert" isVisible={lastPackingList}>
                        <div className="text-left">Last Used in <strong>{lastPackingListData.VehicleRegistration}</strong> on <strong>{moment(lastPackingListData.LoadingDate).format("dddd D MMM YYYY")}</strong>.</div> 
                        <div className="text-left"><a href={"/packinglists/view/" + lastPackingListData.PackingListId}>View Packing List</a></div> 
                    </InfoAlert>
                    <Form.Row className="align-items-center">
                        <Form.Group as={Col} xs="12" md="6" className="align-self-start"> 
                            <RequiredFormLabel>Name</RequiredFormLabel>
                            <Form.Control required placeholder="Name" 
                                name="EquipmentName"                        
                                onChange={handleChange}
                                value={values.EquipmentName}/>

                            <Form.Check className="mt-3" inline type="checkbox" label="Has Multiple Modules?" id="MultipleModulesCheckbox"
                                name="IsImportant" data-testid="MultipleModulesCheckbox"
                                onChange={handleChange} 
                                checked={values.IsImportant}  />
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="6">
                            <RequiredFormLabel>Category</RequiredFormLabel>
                            <ReactTags
                                suggestions={categories}
                                tags={values.Categories}
                                minQueryLength={0}
                                maxSuggestionsLength={categories.length}
                                onDelete={deleteCategory}
                                onAddition={addCategory} 
                                placeholderText="Select Categories"
                                removeButtonText="Remove Category"
                                />
                        </Form.Group>
                    </Form.Row>
                    <h5 className="form-section">Required Equipment</h5>
                    <Form.Row>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>Pegs</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="Pegs"  
                                onChange={handleChange}
                                value={values.Pegs}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>10 Amp Leads</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="TenAmpLeads"  
                                onChange={handleChange}
                                value={values.TenAmpLeads}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>Hoses</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="Hoses"  
                                onChange={handleChange}
                                value={values.Hoses}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>Sandbags</Form.Label>
                            <Form.Control type="number"
                                min="0" 
                                name="Sandbags"  
                                onChange={handleChange}
                                value={values.Sandbags} />
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>1.5 HP Blowers</Form.Label>
                            <Form.Control type="number"
                                min="0" 
                                name="OneHPBlowers"  
                                onChange={handleChange}
                                value={values.OneHPBlowers} />
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Label>2 HP Blowers</Form.Label>
                            <Form.Control type="number" 
                                min="0" 
                                name="TwoHPBlowers"  
                                onChange={handleChange}
                                value={values.TwoHPBlowers}/>
                        </Form.Group>
                    </Form.Row>
                    <h5 className="form-section">Extra Equipment</h5>
                    <DraggableEquipmentList equipmentName="ExtraEquipment" equipmentList={values.ExtraEquipment} setValues={setValues} onChange={handleChange} />

                    <LoaderButton data-testid="SubmitButton"
                        isLoading={loading}
                        variant="primary"
                        type="submit">Save</LoaderButton>
                </React.Fragment>}
            </Form>
        </div>
    )
}
