import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { ErrorAlert } from '../../components/alerts/Alerts';

import UpcomingPackingLists from './UpcomingPackingLists';
import SearchPackingLists from './SearchPackingLists';
import UpcomingTransferComments from './UpcomingTransferComments';
import VehicleKits from './VehicleKits';

export function Home() {

    const [error, setError] = React.useState("");

    return (
        <Row>
            <Col xs="12" className="mb-3">
                <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
                <h1>Packing Lists</h1>
                <a href="/packinglists/edit" className="btn btn-secondary">Create Packing List</a>
            </Col>
            <Col xs="12" sm="4" className="mb-3">
                <UpcomingPackingLists setError={setError} className="mb-3"/>
                <SearchPackingLists setError={setError} />
            </Col>
            <Col xs="12" sm="4" className="mb-3"> 
                <UpcomingTransferComments setError={setError}/>
            </Col>
            <Col xs="12" sm="4" className="mb-3">
                <VehicleKits setError={setError}/>
            </Col>
        </Row>
    );
}
