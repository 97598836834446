import React, { useState, useEffect, useRef } from 'react';
import produce from "immer";
import { set, has } from "lodash";

function enhancedReducer(state, updateArg) {
    if (has(updateArg, "_path") && has(updateArg, "_value")) {
        const { _path, _value } = updateArg;
    
        return produce(state, draft => {
            set(draft, _path, _value);
        });
    } else {
        return { ...state, ...updateArg };
    }
}

const useCustomForm = ({initialValues,onSubmit}) => {
    const [values, setValues] = React.useReducer(enhancedReducer, initialValues);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});
    
    const formRendered = useRef(true);
    
    useEffect(() => {
        if (!formRendered.current) {
            setValues(initialValues);
            setErrors({});
            setTouched({});
        }
        formRendered.current = false;
    }, [initialValues]);
    
    const handleChange = (event) => {
        const { target } = event;
        const { name, value } = target;
        const updatePath = name.split(".");

        if (typeof event.persist === "function") { 
            event.persist();
        }
        
        if (updatePath.length === 1) {
            if (target.type === "checkbox"){
                setValues({[name]: !values[name] });
            }
            else if (target.type === "number"){
                setValues({[name]: Number(value) });
            }
            else{
                setValues({[name]: value });
            }
        }
        else {
            setValues({
                _path: name,
                _value: value
            });
        }
        
        console.log(values);
    };
    
    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors({ ...errors });
        onSubmit({ data: values, errors });
    };
    
    return {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setValues
    };
};

export default useCustomForm;
