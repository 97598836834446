import React from 'react';

import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import EquipmentList from "./EquipmentList";

import RequiredFormLabel from "../../components/form/RequiredFormLabel";
import FlexibleHeader from "../../components/FlexibleHeader";


export default function EventItem({values, eventItem, index, setValues, handleChange, loadingEquipment, equipment, trailerSelected}) {
    const toggleEvent = (e) => {     
        e.stopPropagation();
        var events = {...values.Events};
        var event = {...events[eventItem.Id]};
        event.Collapsed = !event.Collapsed;
        events[eventItem.Id] = event;
        setValues({ 'Events': events});
        console.log(values);
    };

    const removeEvent = (e) => {
        e.stopPropagation();
        var events = {...values.Events};
        delete events[eventItem.Id];        
        setValues({ 'Events': events});
    };

    return (
        <Card className="packing-list-event mb-4" data-testid="EventItem">
            <Card.Title data-testid="EventTitle" onClick={toggleEvent} className="packing-list-event-title mb-0">
                <FlexibleHeader
                    left={<span><FontAwesomeIcon icon={faBars} className="ml-1 ml-sm-3" style={{marginTop: '2px'}}/></span>} 
                    middle={<span className="mr-2 ml-2 mr-sm-0 ml-sm-0" data-testid="EventIndex">Event {index + 1} {(index === 0) && '(Loaded Last)'} {(index > 0 && (index + 1) === Object.keys(values.Events).length) && '(Loaded First)'}</span>} 
                    right={<span>
                            <Button data-testid="RemoveEventButton" variant="secondary" size="sm" className="mr-3 d-none d-sm-block" onClick={removeEvent}>Remove</Button>
                            <Button variant="secondary" size="sm" className="mr-1 d-sm-none" onClick={removeEvent}><FontAwesomeIcon fixedWidth icon={faTimes}/></Button>
                        </span>} />
            </Card.Title>
            <Collapse in={!eventItem.Collapsed}>
                <Card.Body>
                    <Form.Row>
                        <Form.Group as={Col} xs="12" md="6"> 
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <Form.Label>Event Name</Form.Label>
                                    <Form.Control placeholder="Event Name" 
                                        name={"Events[" + eventItem.Id + "].EventName"}
                                        onChange={handleChange}
                                        value={eventItem.EventName}/>
                                </Form.Group>
                                <Form.Group as={Col} xs="12">
                                    <RequiredFormLabel>Event Date</RequiredFormLabel>
                                    <DatePicker className="form-control d-block"
                                        name="EventDate" 
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Event Date" 
                                        minDate={new Date()}
                                        selected={Date.parse(eventItem.EventDate)} 
                                        onChange={date => {
                                            var utcDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()));
                                            handleChange({target: {name: "Events[" + eventItem.Id + "].EventDate", value: utcDate.toISOString()}});
                                        }} />
                                </Form.Group>
                                <Form.Group as={Col} xs="12" className="mb-0 mb-sm-3">
                                    <Form.Label>Selected Equipment</Form.Label>
                                    <Form.Control as="select">
                                        {Object.keys(eventItem.Equipment).length === 0 &&
                                            <option data-testid="SelectedEquipmentItem">None</option>
                                        }
                                        {Object.keys(eventItem.Equipment).map((equipmentKey) => {
                                            return <option data-testid="SelectedEquipmentItem" key={equipmentKey}>{eventItem.Equipment[equipmentKey].EquipmentName}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="6"> 
                            <Form.Label>Comments</Form.Label>
                            <Form.Control placeholder="Comments" 
                                as="textarea" rows="4" 
                                name={"Events[" + eventItem.Id + "].Comments"}
                                onChange={handleChange}
                                value={eventItem.Comments}/>
                        </Form.Group>
                    </Form.Row>

                    <EquipmentList values={values} eventItem={eventItem} handleChange={handleChange}
                        loadingEquipment={loadingEquipment} equipment={equipment} trailerSelected={trailerSelected}></EquipmentList>
                    
                </Card.Body>
            </Collapse>
        </Card>)
}