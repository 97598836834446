import { Auth } from 'aws-amplify'

/* istanbul ignore next */
export const getUserSession = async () => {
    var userSession = await Auth.currentSession();
    return userSession;
}

/* istanbul ignore next */
export const getCurrentUserEmail = async () => {
    var currentSession = await getUserSession();
    return currentSession.getIdToken().payload.email;
}