import { getUserSession } from '../utils/UserUtils';


export const deleteRecord = async (tableName, key, keyName, sortKey, sortKeyName, topicArn) => {
    try {
        var userSession = await getUserSession();
        var url = process.env.REACT_APP_API_ENDPOINT + key + '?tableName=' + tableName + '&keyName=' + keyName;

        if (sortKey && sortKeyName) {
            url += "&sortKey=" + sortKey + "&sortKeyName=" + sortKeyName;
        }

        if (topicArn) {
            url += "&messageTopic=" + topicArn;
        }
        
        const response = await fetch(url,
        {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Authorization': "Bearer " + userSession.getIdToken().getJwtToken()
            }
        });
            
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return ({
            isError: false
        });
    } catch (ex) {
        return ({
            isError: true, 
            error: "Unable to delete. The following error was returned: " + ex.message
        });
    }
}

export const getPagedData = async (tableName, columns) => {
    var body = await getData(tableName, columns);

    if (body.isError){
        return body;
    }

    var items = body.Items;
            
    while (body.LastEvaluatedKey !== undefined) {
        body = await getData(tableName, columns, body.LastEvaluatedKey);
        if (body.isError){
            return body;
        }
        items = items.concat(body.Items);
    }

    return items;
}

export const getData = async (tableName, columns, startKey, limit) => {
    try {
        var url = process.env.REACT_APP_API_ENDPOINT + '?tableName=' + tableName;

        if (startKey) {
            url += "&startKey=" + encodeURIComponent(JSON.stringify(startKey));
        }
        if (limit){
            url += "&limit=" + limit;
        }
        if (columns) {
            url += "&attributes=" + encodeURIComponent(JSON.stringify(columns));
        }

        var userSession = await getUserSession();
        const response = await fetch(url,
        {
            mode: 'cors',
            headers: {
                'Authorization': "Bearer " + userSession.getIdToken().getJwtToken()
            }
        });
        
        if (!response.ok) {
            throw Error(response.statusText);
        }

        var responseData = await response.text();
        if (responseData === ''){
            return null;
        }
        return JSON.parse(responseData);
    } catch (ex) {
        return ({
            isError: true, 
            error: "Unable to load " + tableName + " information. The following error was returned: " + ex.message
        });
    }
}

export const getDataByKey = async (tableName, keyName, keyValue, columns, startKey) => {
    try {
        var url = process.env.REACT_APP_API_ENDPOINT + keyValue + '?tableName=' + tableName + '&keyName=' + keyName;

        if (startKey) {
            url += "&startKey=" + encodeURIComponent(JSON.stringify(startKey));
        }

        if (columns) {
            url += "&attributes=" + encodeURIComponent(JSON.stringify(columns));
        }
        var userSession = await getUserSession();
        const response = await fetch(url,
        {
            mode: 'cors',
            headers: {
                'Authorization': "Bearer " + userSession.getIdToken().getJwtToken()
            }
        });
        
        if (!response.ok) {
            throw Error(response.statusText);
        }

        var responseData = await response.text();
        if (responseData === ''){
            return null;
        }
        return JSON.parse(responseData);
    } catch (ex) {  
        return ({
            isError: true, 
            error: "Unable to load " + tableName + " information. The following error was returned: " + ex.message
        }); 
    }
}

export const batchGetDataByKey = async (tableName, keyName, keyValues) => {
    try {
        var url = process.env.REACT_APP_API_ENDPOINT + 'batch?tableName=' + tableName + '&keyName=' + keyName + '&ids=' + encodeURIComponent(JSON.stringify(keyValues));

        var userSession = await getUserSession();
        const response = await fetch(url,
        {
            mode: 'cors',
            headers: {
                'Authorization': "Bearer " + userSession.getIdToken().getJwtToken()
            }
        });
        
        if (!response.ok) {
            throw Error(response.statusText);
        }

        var responseData = await response.text();
        if (responseData === ''){
            return null;
        }
        return JSON.parse(responseData);
    } catch (ex) {  
        return ({
            isError: true, 
            error: "Unable to load " + tableName + " information. The following error was returned: " + ex.message
        }); 
    }
}

export const queryData = async (tableName, indexName, queryExpression, startKey, data, columns, limit, scanForward) => {
    try {
        var url = process.env.REACT_APP_API_ENDPOINT + 'query?tableName=' + tableName + '&query=' + encodeURIComponent(JSON.stringify(queryExpression)) + '&body='+ encodeURIComponent(JSON.stringify(data));

        if (indexName) {
            url += "&indexName=" + indexName;
        }

        if (startKey) {
            url += "&startKey=" + encodeURIComponent(JSON.stringify(startKey));
        }

        if (columns) {
            url += "&attributes=" + encodeURIComponent(JSON.stringify(columns));
        }

        if (limit) {
            url += "&limit=" + limit;
        }

        if (scanForward !== undefined) {
            url += "&scanForward=" + scanForward;
        }

        var userSession = await getUserSession();
        const response = await fetch(url,
        {
            mode: 'cors',
            headers: {
                'Authorization': "Bearer " + userSession.getIdToken().getJwtToken()
            }
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }

        var responseData = await response.text();
        if (responseData === ''){
            return null;
        }
        return JSON.parse(responseData);
    } catch (ex) {  
        return ({
            isError: true, 
            error: "Unable to load " + tableName + " information. The following error was returned: " + ex.message
        }); 
    }
}


export const pageQueryData = async (tableName, indexName, queryExpression, data, columns) => {
    var body = await queryData(tableName, indexName, queryExpression, null, data, columns);

    if (body.isError){
        return body;
    }

    var items = body.Items;
            
    while (body.LastEvaluatedKey !== undefined) {
        body = await queryData(tableName, indexName, queryExpression, body.LastEvaluatedKey, data, columns);
        if (body.isError){
            return body;
        }
        items = items.concat(body.Items);
    }

    return items;
}

export const saveData = async (tableName, keyName, data, topicArn, sortKeyName) => {
    try {
        var userSession = await getUserSession();
        
        const requestOptions = {
            method: 'POST',
            mode: 'cors',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + userSession.getIdToken().getJwtToken()
            },
            body: JSON.stringify(
                {
                    tableName: tableName,
                    keyName: keyName,
                    sortKeyName: sortKeyName,
                    data: data,
                    messageTopic: topicArn
                })
        };
            
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT, requestOptions);

        if (!response.ok) {
            throw Error(response.statusText);
        }

        var responseData = await response.text();
        if (responseData === ''){
            return null;
        }
        return JSON.parse(responseData);
    } catch (ex) {
        return ({
            isError: true, 
            error: "Unable to load " + tableName + " information. The following error was returned: " + ex.message
        });
    }
}