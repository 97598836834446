import React, { useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { useRouter } from "../../components/Router";
import { getDataByKey, deleteRecord, saveData } from '../../utils/FetchUtils';
import ViewEquipmentList from "../../components/ViewEquipmentList";

import {getCurrentUserEmail} from '../../utils/UserUtils';
import {getDateWithoutTime} from '../../utils/DateUtils';

import OrderBy from 'lodash/orderBy';
import Filter from 'lodash/filter';
import Some from 'lodash/some';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export function ViewPackingList() {

    const vehicleData = {
        Registration:'',
        OrderedVehicleKit: {
            Pegs: {Id: uuidv4(), Name: "Pegs", Quantity: 0},
            TenAmpLeads: {Id: uuidv4(), Name: "10 Amp Leads", Quantity: 0},
            Hoses: {Id: uuidv4(), Name: "Hoses", Quantity: 0},
            OneHPBlowers: {Id: uuidv4(), Name: "1.5HP Blowers", Quantity: 0},
            TwoHPBlowers: {Id: uuidv4(), Name: "2HP Blowers", Quantity: 0}
        },
        OrderedExtraEquipmentKit: {},
        OrderedCabinEquipmentKit: {},
    }

    const initialData = {
        Id: '',
        JobName:'',
        LoadingDate:new Date().toISOString(),
        Complete: false,
        Vehicle: {
            ...vehicleData
        },

        Comments: '',
        OrderedEvents:[],

        OrderedEquipmentToLoad: {
            Pegs: {Id: uuidv4(), Name: "Pegs", Quantity: 0},
            TenAmpLeads: {Id: uuidv4(), Name: "10 Amp Leads", Quantity: 0},
            Hoses: {Id: uuidv4(), Name: "Hoses", Quantity: 0},
            Sandbags: {Id: uuidv4(), Name: "Sandbags", Quantity: 0},
            OneHPBlowers: {Id: uuidv4(), Name: "1.5HP Blowers", Quantity: 0},
            TwoHPBlowers: {Id: uuidv4(), Name: "2HP Blowers", Quantity: 0}
        }
    }

    const [data, setData] = React.useState(initialData);
    const [editable, setEditable] = React.useState(false);
    
    const [vehicleCheckedDate, setVehicleCheckedDate] = React.useState(null);
    const [trailerCheckedDate, setTrailerCheckedDate] = React.useState(null);

    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const router = useRouter();
    const id = React.useRef(undefined);

    useEffect(() => {
        const fetchData = async () => {
            if (router.query.id === undefined){
                window.location.href = "/";
            }
            else {
                id.current = router.query.id;
            }
        
            setLoading(true);
            setError(null);
            setInfo(null);
    
            try {
                var responseData = await getDataByKey("PackingList", "Id", id.current);
                if (!responseData) {
                    setLoading(false);
                    setError("Unable to find a Packing List with the ID " + id.current);
                    return;
                } else if (responseData.isError) {
                    setError(responseData.error);
                    setLoading(false);
                    return;
                }

                if (new Date(responseData.LoadingDate).toISOString() < getDateWithoutTime().toISOString()) {
                    setEditable(false);
                } else {
                    setEditable(true);
                }

                responseData.Vehicle.OrderedVehicleKit = OrderBy(responseData.Vehicle.VehicleKit, "Name");
                responseData.Vehicle.OrderedExtraEquipmentKit = OrderBy(responseData.Vehicle.ExtraEquipmentKit, "Order");
                responseData.Vehicle.OrderedCabinEquipmentKit = OrderBy(responseData.Vehicle.CabinEquipmentKit, "Order");

                responseData.OrderedEvents = OrderBy(responseData.Events, "EventOrder", "desc");
                
                for (var i = 0; i < responseData.OrderedEvents.length; i++){
                    responseData.OrderedEvents[i].OrderedEquipment = OrderBy(responseData.OrderedEvents[i].Equipment, "EquipmentName");
                }

                responseData.OrderedEquipmentToLoad = OrderBy(responseData.EquipmentToLoad, "Order");

                var vehicleLastCheckedDate = await getDataByKey("Vehicle", "Id", responseData.Vehicle.Id, ["LastCheckedDate"]);
                if (vehicleLastCheckedDate && vehicleLastCheckedDate.LastCheckedDate){
                    setVehicleCheckedDate(vehicleLastCheckedDate.LastCheckedDate);
                }

                if (responseData.Trailer) { 
                    responseData.Trailer.OrderedVehicleKit = OrderBy(responseData.Trailer.VehicleKit, "Name");
                    responseData.Trailer.OrderedExtraEquipmentKit = OrderBy(responseData.Trailer.ExtraEquipmentKit, "Order");
                    responseData.Trailer.OrderedCabinEquipmentKit = OrderBy(responseData.Trailer.CabinEquipmentKit, "Order");
                    var trailerLastCheckedDate = await getDataByKey("Vehicle", "Id", responseData.Trailer.Id, ["LastCheckedDate"]);
                    if (trailerLastCheckedDate && trailerLastCheckedDate.LastCheckedDate){
                        setTrailerCheckedDate(trailerLastCheckedDate.LastCheckedDate);
                    }
                }

                setData({...initialData, ...responseData});
                setLoading(false);
                console.log(responseData);
            } catch (ex) {
                setError("Unable to load Packing List information. The following error was returned: " + ex.message);      
            }
        }

        fetchData();
    }, []);

    const deletePackingList = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='delete-item'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this packing list?</p>
                        <Button variant="secondary" onClick={onClose}>No</Button>
                        <Button data-testid="ConfirmDelete" variant="danger"
                            onClick={async() => {
                                try {
                                    var equipmentIds = [];
                                    Object.keys(data.Events).forEach(function(eventId) {
                                        var eventItem = data.Events[eventId];
                                        Object.keys(eventItem.Equipment).forEach(function(equipmentId) {
                                            var equipment = eventItem.Equipment[equipmentId];
                                            if (!equipmentIds.includes(equipment.Id)) {
                                                equipmentIds.push(equipment.Id);                                                    
                                            }
                                        });
                                    });

                                    for (var i = 0; i < equipmentIds.length; i++) {
                                        await deleteRecord("PackingListEquipment", data.Id, "PackingListId", equipmentIds[i], "EquipmentId");
                                    };

                                    var response = await deleteRecord("PackingList", data.Id, "Id", null, null, process.env.REACT_APP_TRANSFER_TOPIC);            
                                    if (response.isError) {
                                        setError(response.error);
                                    } else {
                                        setInfo("Packing List has been deleted.");

                                        /* istanbul ignore next */
                                        setTimeout(()=>window.location.href = "/", 1000);
                                    }
                                } catch (ex) {
                                    setError("Unable to delete. The following error was returned: " + ex.message);      
                                }
                                onClose();
                            }}>Delete</Button>
                    </div>
                );
            }
        });
    };

    const markComplete = async () => {
        var uncheckedEquipment = [];
        
        // check vehicle equipment
        var uncheckedVehicleEquipment = OrderBy(Filter(data.Vehicle.VehicleKit, function(item) { return item.Quantity > 0 && !item.selected; }), "Name");
        uncheckedEquipment.push(...uncheckedVehicleEquipment);
        var uncheckedVehicleExtraEquipment = OrderBy(Filter(data.Vehicle.ExtraEquipmentKit, function(item) { return item.Quantity > 0 && !item.selected; }), "Order");
        uncheckedEquipment.push(...uncheckedVehicleExtraEquipment);
        var uncheckedVehicleCabinEquipment = OrderBy(Filter(data.Vehicle.CabinEquipmentKit, function(item) { return item.Quantity > 0 && !item.selected; }), "Order");
        uncheckedEquipment.push(...uncheckedVehicleCabinEquipment);

        // check trailer equipment
        if (data.Trailer)  {
            var uncheckedTrailerEquipment = OrderBy(Filter(data.Trailer.VehicleKit, function(item) { return item.Quantity > 0 && !item.selected; }), "Name");
            uncheckedEquipment.push(...uncheckedTrailerEquipment);
            var uncheckedTrailerExtraEquipment = OrderBy(Filter(data.Trailer.ExtraEquipmentKit, function(item) { return item.Quantity > 0 && !item.selected; }), "Order");
            uncheckedEquipment.push(...uncheckedTrailerExtraEquipment);
            var uncheckedTrailerCabinEquipment = OrderBy(Filter(data.Trailer.CabinEquipmentKit, function(item) { return item.Quantity > 0 && !item.selected; }), "Order");
            uncheckedEquipment.push(...uncheckedTrailerCabinEquipment);
        }

        // check ToAdd equipment
        var uncheckedEquipmentToLoad = OrderBy(Filter(data.EquipmentToLoad, function(item) { return item.Quantity > 0 && !item.selected; }), "Order");
        uncheckedEquipment.push(...uncheckedEquipmentToLoad);

        // check event equipment
        var uncheckedEventEquipment = {}
        Object.keys(data.Events).forEach(eventKey => {
            var uncheckedEventItemEquipment = OrderBy(Filter(data.Events[eventKey].Equipment, function(item) {
                return !item.selected; 
            }), "EquipmentName");
            if (uncheckedEventItemEquipment.length > 0){
                uncheckedEventEquipment[eventKey] =uncheckedEventItemEquipment;
                uncheckedEquipment.push(...uncheckedEventEquipment[eventKey]);
            }
        });
        
        if (uncheckedEquipment.length > 0){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='confirmation-dialog'>
                            <h1>Unable to complete?</h1>
                            <p>The following equipment has not been checked. Make sure you have loaded all equipment before completing the Packing List.</p>
                            <ul className="text-left">
                                {((uncheckedVehicleEquipment && uncheckedVehicleEquipment.length > 0) || 
                                (uncheckedVehicleExtraEquipment && uncheckedVehicleExtraEquipment.length > 0)|| 
                                (uncheckedVehicleCabinEquipment && uncheckedVehicleCabinEquipment.length > 0)) && 
                                <li>Vehicle Kit
                                    <ul>
                                        {uncheckedVehicleEquipment.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                        {uncheckedVehicleExtraEquipment.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                        {uncheckedVehicleCabinEquipment.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                    </ul>
                                </li>}
                                {((uncheckedTrailerEquipment && uncheckedTrailerEquipment.length > 0) || 
                                (uncheckedTrailerExtraEquipment && uncheckedTrailerExtraEquipment.length > 0) || 
                                (uncheckedTrailerCabinEquipment && uncheckedTrailerCabinEquipment.length > 0)) && 
                                <li>Trailer Kit
                                    <ul>
                                        {uncheckedTrailerEquipment.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                        {uncheckedTrailerExtraEquipment.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                        {uncheckedTrailerCabinEquipment.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                    </ul>
                                </li>}
                                {uncheckedEquipmentToLoad && uncheckedEquipmentToLoad.length > 0 && 
                                <li>Equipment To Add
                                    <ul>
                                        {uncheckedEquipmentToLoad.map((item, index) => {
                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.Name}</li>)
                                        })}
                                    </ul>
                                </li>}
                                {Object.keys(uncheckedEventEquipment).length > 0 && 
                                <li>Event Equipment
                                    <ul>
                                        {Object.keys(uncheckedEventEquipment).map((eventKey) => {
                                            return (
                                                <li key={uuidv4()}>
                                                    {data.Events[eventKey].EventName}
                                                    <ul> 
                                                        {uncheckedEventEquipment[eventKey].map((item, index) => {
                                                            return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.EquipmentName}</li>)
                                                        })}
                                                    </ul>
                                                </li>                                            
                                            )
                                        })}
                                    </ul>
                                </li>}
                            </ul>
                            <Button data-testid="CloseConfirm" variant="secondary" onClick={onClose}>Close</Button>
                        </div>
                    );
                }
            });
        } else {
            var user = getCurrentUserEmail();
            data.Complete = true;
            data.CompletedDate = new Date();
            savePackingList({
                "Complete": data.Complete,
                "CompletedDate": data.CompletedDate,
                "CompletedBy": user
            });
            setData({...data});
        }
    };

    const savePackingList = async (dataToSave) => {
        try {
            var saveResponse = await saveData("PackingList", "Id", {Id: data.Id, ...dataToSave});
            if (saveResponse.isError) {
                setError(saveResponse.error);
                return;
            }
        } catch (ex) {
            setError("Unable to save Packing List. The following error was returned: " + ex.message);      
        }
    };

    return (
        <div data-testid="PackingListWrapper" className={"packing-list"}>
            <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Packing List</LoadingAlert>
            <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
            <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>
            <InfoAlert data-testid="CompleteAlert" isVisible={data.Complete}><h4 className="alert-heading mb-0">Packing List Complete</h4>{moment(data.CompletedDate).format("ddd DD/MM/YYYY hh:mm:ss A")}</InfoAlert>            

            {!loading && 
            <React.Fragment>
            <Row className="pb-3">
                <Col xs="12" sm="6">
                    <h1>Packing List</h1>
                    <h2>{moment(data.LoadingDate).format("dddd D MMM YYYY")}</h2>
                </Col>
                <Col xs="12" sm="6" className="text-sm-right mt-2">
                    <Button data-testid="CompleteButton" variant="info" className="mr-2 mb-0" onClick={()=>markComplete()}>Mark Complete</Button>
                    <a data-testid="EditButton" className="btn btn-secondary mr-2 mb-0" href={"/packinglists/edit/" + data.Id}>Edit</a>
                    <Button data-testid="DeleteButton" variant="danger" onClick={()=>deletePackingList()}>Delete</Button>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col xs="12" sm="6">
                    <dl className="packing-list-info important-info">
                        <dt><h4>Vehicle</h4></dt>
                        <dd>{data.Vehicle.Registration}</dd>
                        {data.Trailer && 
                            <React.Fragment>
                                <dt><h4>Trailer</h4></dt>
                                <dd>{data.Trailer.Registration}</dd>
                            </React.Fragment>
                        }
                    </dl>
                </Col>
                <Col xs="12" sm="6" className="text-sm-right">
                    <Card className="packing-list-event">
                        <Card.Title as="h4" className="mb-0">
                            Comments
                        </Card.Title>
                        <Card.Body>
                            {data.OrderedEvents.map((eventItem, index) => {
                                return (
                                    <dd key={uuidv4()}>
                                        <h5 className="mb-0">Event {eventItem.EventOrder + 1}</h5>
                                        {(!eventItem.Comments || eventItem.Comments === "") ? "None" : eventItem.Comments }
                                    </dd>
                                )
                            })}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm="6">
                    <h5 className="equipment-section">Vehicle</h5>
                    <table className='table table-striped table-bordered equipment-table'>
                        <tbody data-testid="VehicleEquipment">
                            <ViewEquipmentList data={data} equipmentList={data.Vehicle.OrderedVehicleKit} selector="Vehicle.VehicleKit" 
                                title="Vehicle Kit" savePackingList={savePackingList} setData={setData} >
                                    <small data-testid="VehicleCheckedDate">(Last Checked {vehicleCheckedDate ? moment(vehicleCheckedDate).format("ddd DD/MM/YYYY hh:mm:ss A") : "Never"})</small>
                            </ViewEquipmentList>

                            <ViewEquipmentList data={data} equipmentList={data.Vehicle.OrderedExtraEquipmentKit} selector="Vehicle.ExtraEquipmentKit" 
                                title="Extras" savePackingList={savePackingList} setData={setData} />

                            <ViewEquipmentList data={data} equipmentList={data.Vehicle.OrderedCabinEquipmentKit} selector="Vehicle.CabinEquipmentKit" 
                                title="Cabin Equipment" savePackingList={savePackingList} setData={setData} />

                            {(data.Trailer && (data.Trailer.OrderedVehicleKit.length > 0 || data.Trailer.OrderedExtraEquipmentKit.length > 0 || data.Trailer.OrderedCabinEquipmentKit.length > 0)) &&
                            (
                                <React.Fragment>
                                    <ViewEquipmentList data={data} equipmentList={data.Trailer.OrderedVehicleKit} selector="Trailer.VehicleKit" 
                                        title="Trailer Kit" savePackingList={savePackingList} setData={setData} >
                                            <small data-testid="TrailerCheckedDate">(Last Checked {trailerCheckedDate ? moment(trailerCheckedDate).format("ddd DD/MM/YYYY hh:mm:ss A") : "Never"})</small>
                                    </ViewEquipmentList>

                                    <ViewEquipmentList data={data} equipmentList={data.Trailer.OrderedExtraEquipmentKit} selector="Trailer.ExtraEquipmentKit" 
                                        title="Extras" savePackingList={savePackingList} setData={setData} />

                                    <ViewEquipmentList data={data} equipmentList={data.Trailer.OrderedCabinEquipmentKit} selector="Trailer.CabinEquipmentKit" 
                                        title="Cabin Equipment" savePackingList={savePackingList} setData={setData} />
                                </React.Fragment>
                            )}
                        </tbody>
                    </table>
                </Col>
                <Col xs="12" sm="6"> 
                    <h5 className="equipment-section">Events</h5>
                    {data.OrderedEvents.map((eventItem, index) => {
                        return (
                            <Card key={index} className="packing-list-event mb-2">
                                <Card.Title className="mb-0">
                                    Event {eventItem.EventOrder + 1} {(eventItem.EventOrder === 0) && '(Load Last)'} {(eventItem.EventOrder > 0 && (eventItem.EventOrder + 1) === Object.keys(data.Events).length) && '(Load First)'}
                                </Card.Title>
                                <Card.Body>
                                    <Row>
                                        <Col xs="12"> 
                                            <h4>{eventItem.EventName}</h4>
                                            <h6>{moment(eventItem.EventDate).format("dddd D MMM YYYY")}</h6>
                                        </Col>
                                    </Row>
                                    <h5 className="form-section">Equipment</h5>
                                        <table className='table table-striped table-bordered equipment-table m-0'>
                                            <tbody data-testid="EventEquipment">
                                                <ViewEquipmentList data={data} equipmentList={eventItem.OrderedEquipment} selector={"Events." + eventItem.Id + ".Equipment"}
                                                    savePackingList={savePackingList} setData={setData} nameKey="EquipmentName" overrideQuantity={true} />
                                            </tbody>
                                        </table>
                                </Card.Body>
                            </Card>
                        );
                    })}
                    {(data.OrderedEquipmentToLoad && Some(Array.from(data.OrderedEquipmentToLoad), function(x) { return x.Quantity > 0; })) &&
                    (
                        <React.Fragment>
                            <h5 className="equipment-section">Equipment To Add</h5>
                            <table className='table table-striped table-bordered equipment-table'>
                                <tbody data-testid="EquipmentToLoad">
                                    <ViewEquipmentList data={data} equipmentList={data.OrderedEquipmentToLoad} selector="EquipmentToLoad" 
                                        savePackingList={savePackingList} setData={setData} />
                                </tbody>
                            </table>
                        </React.Fragment>
                    )}
                </Col>
            </Row>
            </React.Fragment>}
        </div>     
    )
}
