import React from 'react';
import CheckIndicator from '../components/CheckIndicator';
import { getCurrentUserEmail } from '../utils/UserUtils';

import FindKey from 'lodash/findKey';

import { v4 as uuidv4 } from 'uuid';

export function EquipmentItemRow({item, onClick, nameKey = "Name"}) {
    return (
        <tr data-testid="EquipmentItemRow" data-item={item.Id} className={"equipment-item" + (item.selected ? " selected": "") + (item.IsImportant ? " important": "")} onClick={()=>onClick(item)}>
            <td data-testid="equipment-item-row" className="align-middle border-right-0">
                <span className="d-flex justify-content-between">
                    <span data-testid="EquipmentItemName">{item[nameKey]}{(item.Quantity && item.Quantity > 0) && (" x " + item.Quantity)}</span>
                    {item.AddToTrailer && <span data-testid="EquipmentItemTrailer" className="text-danger font-weight-bold text-center">Add To Trailer</span>}
                </span>
            </td>
            <td width="5%" className="align-middle text-center">
                <CheckIndicator />
            </td>
        </tr>
    );
}

export function EquipmentItemRows({equipmentList, setData}) {

    const toggleEquipmentItem = async (equipmentList, itemId) => {     
        var equipmentKey = FindKey(equipmentList, function(x) { return x.Id === itemId; });
        var userEmail = await getCurrentUserEmail();

        equipmentList[equipmentKey].selected = !equipmentList[equipmentKey].selected;
        equipmentList[equipmentKey].SelectedDate = equipmentList[equipmentKey].selected ? new Date(): null;
        equipmentList[equipmentKey].SelectedBy = equipmentList[equipmentKey].selected ? userEmail : null;

        setData();
    };

    return (
        <React.Fragment>
            {equipmentList.map((item) => {
                return (item.Quantity > 0) && 
                    <EquipmentItemRow key={uuidv4()} item={item} onClick={() => toggleEquipmentItem(equipmentList, item.Id)}/>
            })}
        </React.Fragment>
    );
}