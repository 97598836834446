import React, { useEffect } from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Form from 'react-bootstrap/Form';

import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import GroupBy from 'lodash/groupBy';

import moment from 'moment';

import LoaderButton from '../../components/LoaderButton';
import RequiredFormLabel from "../../components/form/RequiredFormLabel";
import { ListItemLoadingAlert, ListItemInfoAlert} from '../../components/alerts/Alerts';
import { queryData, saveData, deleteRecord } from '../../utils/FetchUtils';
import { getDateWithoutTime, getDateKey } from '../../utils/DateUtils';
import useCustomForm from '../../components/Form';
import {getCurrentUserEmail} from '../../utils/UserUtils';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlus, faTimes, faPencilAlt, faCheck } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export default function UpcomingTransferComments({setError}) {

    const initialCommentData = {
        Id: uuidv4(),
        TransferComment: '',
        TransferDate: '',
        TransferStaff: '',
        CreatedDate: new Date(),
        UpdatedDate: new Date()
    }

    const [addComment, setAddComment] = React.useState(false);
    const [commentData, setCommentData] = React.useState(initialCommentData);
    const [loading, setLoading] = React.useState(false);
    const [upComingTransfers, setUpComingTransfers] = React.useState([]);
    const [loadingUpcomingTransfers, setLoadingUpcomingTransfers] = React.useState(false);

    const attributesToGet =  ["Id", "TransferDate", "TransferComment", "TransferStaff", "Complete", "CompletedDate"];

    const refreshUpcomingTransfers = React.useCallback(async () => {
        loadUpcomingTransfers();
    },[]);

    const loadUpcomingTransfers = React.useCallback(async () => {
        setLoadingUpcomingTransfers(true);
        setError(null);
    
        try {
            var transfers = [];
            var currentDate = getDateWithoutTime();
            var currentDateKey = getDateKey();
            var oneWeekDate = new Date(moment(currentDate).add(7,'d'));

            var responseData = await queryData("TransferComments", "DateIndex", "TransferDateKey = :dateKeyValue AND TransferDate BETWEEN :fromDateValue AND :toDateValue", null, 
                {"dateKeyValue": currentDateKey, "fromDateValue": currentDate.toISOString(), "toDateValue": oneWeekDate.toISOString()}, attributesToGet);
            if (responseData.isError) {
                setLoadingUpcomingTransfers(false);
                setError(responseData.error);
                return;
            }

            transfers.push(...responseData);

            if (oneWeekDate.getMonth() !== currentDate.getMonth()){
                // next week goes to the next month, so get next month's lists just in case
                var nextDateKey = getDateKey(oneWeekDate);

                var nextPeriodData = await queryData("TransferComments", "DateIndex", "TransferDateKey = :dateKeyValue AND TransferDate BETWEEN :fromDateValue AND :toDateValue", null, 
                    {"dateKeyValue": nextDateKey, "fromDateValue": currentDate.toISOString(), "toDateValue": oneWeekDate.toISOString()}, attributesToGet);
                if (nextPeriodData.isError) {
                    setLoadingUpcomingTransfers(false);
                    setError(nextPeriodData.error);
                    return;
                }

                transfers.push(...nextPeriodData);
            }

            // check from the filter
            if (transfers.length !== 0) {
                var groupedLists = GroupBy(transfers, function (item) {
                    return item.TransferDate;
                });

                console.log(groupedLists);
                setUpComingTransfers(groupedLists);
            } else {
                setUpComingTransfers([]);
            }
            await setLoadingUpcomingTransfers(false);
        } catch (ex) {
            setError("Unable to load Transfer information. The following error was returned: " + ex.message);      
            setLoadingUpcomingTransfers(false);
        }
    }, []);

    const addTransferComment = React.useCallback(() => {
        initialCommentData.Id = uuidv4();
        initialCommentData.CreatedDate = new Date();
        setCommentData({...initialCommentData});
        setAddComment(true);
    }, []);

    const submitComment = React.useCallback(async (values) => {
        if (!values.data.TransferComment)
        {
            setError("Please enter a Transfer Comment.");
            return;
        }

        if (!values.data.TransferDate) {
            setError("Please enter a Transfer Date.");
            return;
        }

        setLoading(true);
        setError(null);

        try {
            values.data.TransferDateKey = getDateKey(new Date(values.data.TransferDate));
            values.data.UpdatedDate = new Date();
            values.data.Complete = false;
            values.data.CompletedDate = null;
            values.data.CompletedBy = null;

            var saveResponse = await saveData("TransferComments", "Id", values.data);
            if (saveResponse.isError){
                setError(saveResponse.error);
                return;
            }
            setLoading(false);
            setAddComment(false);
            loadUpcomingTransfers();
        } catch (ex) {
            setLoading(false);
            setError("Unable to save Transfer Comment. The following error was returned: " + ex.message);      
        }
    }, [commentData])

    const deleteTransferComment = (commentId) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='delete-item'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this comment?</p>
                        <Button variant="secondary" onClick={onClose}>No</Button>
                        <Button data-testid="ConfirmDelete" variant="danger"
                            onClick={async() => {                                    
                                try {
                                    var response = await deleteRecord("TransferComments", commentId, "Id");            
                                    if (response.isError) {
                                        setError(response.error);
                                    } else {
                                        loadUpcomingTransfers();
                                    }
                                } catch (ex) {
                                    setError("Unable to delete. The following error was returned: " + ex.message);      
                                }
                                onClose();
                            }}>Delete</Button>
                    </div>
                );
            }
        });
    };

    const editTransferComment = (comment) => {
        initialCommentData.Id = comment.Id;
        initialCommentData.TransferComment = comment.TransferComment;
        initialCommentData.TransferStaff = comment.TransferStaff ?? '';
        initialCommentData.TransferDate = comment.TransferDate;
        initialCommentData.CreatedDate = comment.CreatedDate;
        setCommentData({...initialCommentData});
        setAddComment(true);
    };

    const completeTransferComment =async (comment) => {
        var user = getCurrentUserEmail();
        comment.Complete = true;
        comment.CompletedDate = new Date();
        comment.CompletedBy = user;

        var saveResponse = await saveData("TransferComments", "Id", comment);
        if (saveResponse.isError){
            setError(saveResponse.error);
            return;
        }
        setCommentData({...comment});
    };

    const {
        values,
        handleChange,
        handleSubmit
        } = useCustomForm({initialValues: commentData, onSubmit: submitComment});

    useEffect(() => {
        loadUpcomingTransfers();
    }, []);
    return (
    <Card>
        <Card.Body>
            <Card.Title className="m-0 d-flex align-self-start">
                Upcoming Transfer Comments
                <span role="button">
                    <FontAwesomeIcon data-testid="RefreshTransfers" onClick={refreshUpcomingTransfers} icon={faSync}  />
                </span>
            </Card.Title>
        </Card.Body>
        <ListGroup className="list-group-flush packing-list-group">
            <ListItemLoadingAlert data-testid="LoadingAlert" isVisible={loadingUpcomingTransfers}>Loading Transfer Comments</ListItemLoadingAlert>
            <ListGroupItem data-testid="AddTransferComment" action onClick={addTransferComment} variant="primary" className="text-center"><FontAwesomeIcon  icon={faPlus} /> Add Transfer Comment</ListGroupItem>
            {addComment && <ListGroupItem data-testid="AddCommentItem">
                <Form onSubmit={handleSubmit} data-testid="AddCommentForm">
                    <Form.Row className="align-items-center">
                        <Form.Group as={Col} xs="12">
                            <RequiredFormLabel>Transfer Date</RequiredFormLabel>
                            <DatePicker className="form-control d-block"
                                name="TransferDate" 
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Transfer Date" 
                                minDate={new Date()}
                                selected={Date.parse(values.TransferDate)} 
                                onChange={date => {
                                    var utcDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()));
                                    handleChange({target: {name: "TransferDate", value: utcDate.toISOString()}});
                                }}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="12"> 
                            <Form.Label>Transfer Staff</Form.Label>
                            <Form.Control placeholder="Transfer Staff" 
                                name="TransferStaff"                        
                                onChange={staff => {
                                    handleChange({target: {name: "TransferStaff", value: staff.target.value.toLowerCase()}});
                                }}
                                value={values.TransferStaff}/>
                        </Form.Group>
                        <Form.Group as={Col} xs="12"> 
                            <Form.Label>Comments</Form.Label>
                            <Form.Control placeholder="Comments" 
                                as="textarea" rows="3" 
                                name={"TransferComment"}
                                onChange={handleChange}
                                value={values.TransferComment}/>
                        </Form.Group>
                    </Form.Row>
                    <LoaderButton
                        isLoading={loading}
                        variant="primary"
                        type="submit">Save Comment</LoaderButton>
                </Form>
            </ListGroupItem>}  
            <ListItemInfoAlert data-testid="NoTransfersAlert" isVisible={upComingTransfers.length === 0 && !loadingUpcomingTransfers}>No Transfer Comments found.</ListItemInfoAlert>
            {Object.keys(upComingTransfers).map((transferDate) => {
                return (
                <span key={uuidv4()}>
                    <div className="packinglist-date border-0 list-group-item list-group-item-action">
                        <strong>{moment(transferDate).format("dddd D MMM YYYY")}</strong>
                    </div>
                    {upComingTransfers[transferDate].map((item, index) => {
                        return (
                            <div data-testid="TransferCommentItem" key={uuidv4()} className="border-0 list-group-item d-flex justify-content-between align-items-center packinglist-item">
                                <span className="mr-auto" style={{whiteSpace: 'pre-wrap'}}>
                                    {item.TransferComment}{item.TransferStaff && (' - ' + item.TransferStaff)}
                                    {item.Complete &&
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip>{moment(item.CompletedDate).format("ddd DD/MM/YYYY hh:mm:ss A")}</Tooltip>}>
                                            <Badge data-testid="CompleteBadge" className="ml-1 mr-1" variant="success">Complete</Badge>
                                        </OverlayTrigger>}
                                </span>
                                <span role="button">
                                    <FontAwesomeIcon data-testid="CompleteCommentButton" fixedWidth className="mr-2" color="green" icon={faCheck} onClick={() => completeTransferComment(item)} />
                                </span>
                                <span role="button">
                                    <FontAwesomeIcon data-testid="EditCommentButton" fixedWidth className="mr-2" icon={faPencilAlt} onClick={() => editTransferComment(item)} />
                                </span>
                                <span role="button">
                                    <FontAwesomeIcon data-testid="DeleteCommentButton" fixedWidth icon={faTimes} color="darkred" onClick={() => deleteTransferComment(item.Id)} />
                                </span>
                            </div>
                        );
                    })}
                </span>)
            })}

        </ListGroup>
    </Card>
    );
}
