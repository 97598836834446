import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import { useTable, usePagination, useSortBy, useFlexLayout } from 'react-table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { LoadingAlert, ErrorAlert, InfoAlert} from './alerts/Alerts';
import {getPagedData, deleteRecord} from '../utils/FetchUtils'

export default function DataGrid({tableName, primaryKey, columns, allowSort = true, sortColumn = null}) {
    let { url } = useRouteMatch();

    const [data, setData] = React.useState([]);
    const [renderColumns, setColumns] = React.useState(columns);

    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const fetchData = React.useCallback(async () => {
        setError(null);
        setLoading(true);
        try {
            var body = await getPagedData(tableName);
            if (body === undefined) {
                setError("Unable to load " + tableName + " information.");    
                return;
            }
            
            setData(body);
            setLoading(false);
        } catch (ex) {
            /* istanbul ignore next */
            setError("Unable to load " + tableName + " information. The following error was returned: " + ex.message);      
        }
    }, [tableName])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        // Get the state from the instance
        state: { pageIndex, pageSize }
    } = useTable({
        columns: renderColumns,
        data,
        initialState: { 
            pageIndex: 0,
            hiddenColumns: ["Id"],
            sortBy: (allowSort && sortColumn) ? [{id: sortColumn}] : []
        },
        manualPagination: true,
        disableSortBy: !allowSort
    },
    useFlexLayout,
    useSortBy,
    usePagination
    );
    
    useEffect(() => {

        const deleteItem = (key) => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='delete-item'>
                            <h1>Are you sure?</h1>
                            <p>You want to delete this record?</p>
                            <Button variant="secondary" onClick={onClose}>No</Button>
                            <Button data-testid="ConfirmDelete" variant="danger"
                                onClick={async() => {                                    
                                    try {
                                        var response = await deleteRecord(tableName, key, primaryKey);            
                                        if (response.isError) {
                                            setError(response.error);
                                        } else {
                                            setInfo(tableName + " has been deleted.");
                                            /* istanbul ignore next */
                                            setTimeout(()=>window.location.reload(), 1000);
                                        }
                                    } catch (ex) {
                                        /* istanbul ignore next */
                                        setError("Unable to delete. The following error was returned: " + ex.message);      
                                    }
                                    onClose();
                                }}>Delete</Button>
                        </div>
                    );
                }
            });
        };

        const actionCells = 
        {
            id: 'edit',
            accessor: primaryKey,
            width:100,
            className: 'text-right',
            Cell: ({value}) => (
                <div>
                    <a className="btn btn-secondary btn-sm mr-lg-2 mb-2 mb-sm-0" href={url + "/edit/" + value}>Edit</a>
                    <Button data-testid="DeleteButton" variant="danger" size="sm" onClick={()=>deleteItem(value)}>Delete</Button>
                </div>
            )
        };

        const tableColumns = [...columns];
        tableColumns.push(actionCells);

        setColumns(tableColumns);

        fetchData();
    }, [fetchData, pageIndex, pageSize, columns, tableName, primaryKey, url]);

    return(
        <div>            
            <ButtonToolbar className="mb-3">
                <a href={url + "/edit/"} className="btn btn-secondary mr-2">Add {tableName}</a> 
                {/*<Button variant="secondary" >Export To Excel</Button>*/}
            </ButtonToolbar>

            <LoadingAlert isVisible={loading}>Loading {tableName} data</LoadingAlert>
            <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
            <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>
            <InfoAlert data-testid="NoDataAlert" isVisible={!loading && data.length === 0}>No {tableName} data found. Why not <a href={url + "/edit/"}>Add</a> one?</InfoAlert>

            {(!loading && data.length > 0) &&
                    <table {...getTableProps()} className='table table-striped'>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps([
                                    {
                                        className: column.className,
                                        style: column.style,
                                    },
                                    column.getSortByToggleProps()
                                ])}>
                                {column.render("Header")}
                                <span>
                                    {column.isSorted
                                    ? column.isSortedDesc
                                        ? <FontAwesomeIcon icon={faSortDown} size="lg" fixedWidth />
                                        : <FontAwesomeIcon icon={faSortUp} size="lg" fixedWidth />
                                    : ""}
                                </span>
                                </th>
                            ))}
                            </tr>
                        ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps([
                                            {
                                                className: cell.column.className,
                                                style: cell.column.style,
                                            }
                                        ])}>{cell.render("Cell")}</td>
                                    );
                                    })}
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
            }
        </div>
    )    
}
