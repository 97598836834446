import React, { useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import GroupBy from 'lodash/groupBy';

import moment from 'moment';

import { ListItemLoadingAlert, ListItemInfoAlert} from '../../components/alerts/Alerts';
import { queryData } from '../../utils/FetchUtils';
import { getDateWithoutTime, getDateKey } from '../../utils/DateUtils';

import PackingListLink from './PackingListLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export default function UpcomingPackingLists({setError, ...props}) {
    const [upComingPackingLists, setUpComingPackingLists] = React.useState([]);
    const [loadingUpcomingLists, setLoadingUpcomingLists] = React.useState(false);

    const attributesToGet =  ["Id", "LoadingDate", "Vehicle", "Trailer", "Complete", "CompletedDate"];

    const loadUpcomingLists = React.useCallback(async () => {
        setLoadingUpcomingLists(true);
        setError(null);
    
        try {
            var packingLists = [];
            
            var currentDate = getDateWithoutTime();
            var twoDaysAgoDate = new Date(moment(currentDate).subtract(2,'d'));     
            var twoDaysDateKey = getDateKey(twoDaysAgoDate);

            var oneWeekDate = new Date(moment(currentDate).add(7,'d'));            
            var nextDateKey = getDateKey(oneWeekDate);

            // test if 2 days ago is last month....

            var responseData = await queryData("PackingList", "DateIndex", "LoadingDateKey = :dateKeyValue AND LoadingDate BETWEEN :fromDateValue AND :toDateValue", null, 
                {"dateKeyValue": twoDaysDateKey, "fromDateValue": twoDaysAgoDate.toISOString(), "toDateValue": oneWeekDate.toISOString()}, attributesToGet);
            if (responseData.isError) {
                setLoadingUpcomingLists(false);
                setError(responseData.error);
                return;
            }

            packingLists.push(...responseData);

            if (oneWeekDate.getMonth() !== twoDaysAgoDate.getMonth()){
                // next week goes to the next month, so get next month's lists just in case
                var nextPeriodData = await queryData("PackingList", "DateIndex", "LoadingDateKey = :dateKeyValue AND LoadingDate BETWEEN :fromDateValue AND :toDateValue", null, 
                    {"dateKeyValue": nextDateKey, "fromDateValue": twoDaysAgoDate.toISOString(), "toDateValue": oneWeekDate.toISOString()}, attributesToGet);
                if (nextPeriodData.isError) {
                    setLoadingUpcomingLists(false);
                    setError(nextPeriodData.error);
                    return;
                }

                packingLists.push(...nextPeriodData);
            }

            if (packingLists.length !== 0) {
                var groupedLists = GroupBy(packingLists, function (item) {
                    return item.LoadingDate;
                });

                console.log(groupedLists);
                setUpComingPackingLists(groupedLists);
            } else {
                setUpComingPackingLists([]);
            }
            setLoadingUpcomingLists(false);
        } catch (ex) {
            setError("Unable to load Packing List information. The following error was returned: " + ex.message);      
            setLoadingUpcomingLists(false);
        }
    }, []);

    useEffect(() => {
        loadUpcomingLists();
    }, []);

    return (
        <Card {...props}>
            <Card.Body>
                <Card.Title>
                    Upcoming Loading
                    <span role="button" className="float-right"><FontAwesomeIcon onClick={loadUpcomingLists} icon={faSync}  /></span>
                </Card.Title>
                <Card.Subtitle className="text-muted">Next 7 Days</Card.Subtitle>
            </Card.Body>
            <ListGroup className="list-group-flush packing-list-group">
                <ListItemLoadingAlert data-testid="LoadingAlert" isVisible={loadingUpcomingLists}>Loading Packing Lists</ListItemLoadingAlert>
                <ListItemInfoAlert data-testid="NoPackingListsAlert" isVisible={upComingPackingLists.length === 0 && !loadingUpcomingLists}>No Packing Lists found for the next 7 days.</ListItemInfoAlert>
                
                {Object.keys(upComingPackingLists).map((loadingDate) => {
                    return (
                        <span key={uuidv4()}>
                            <ListGroupItem className="packinglist-date border-0">
                                <strong>{moment(loadingDate).format("dddd D MMM YYYY")}</strong>
                            </ListGroupItem>
                            {upComingPackingLists[loadingDate].map((item, index) => {
                                return (
                                    <PackingListLink key={uuidv4()} packingList={item}/>
                                );
                            })}
                        </span>
                    );
                })}
            </ListGroup>
        </Card>
    );
}
