import React from 'react';

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../scss/PackingList.scss';

import logo from '../../img/Monsterball.png';

export function Dashboard() {

    const links = [
        { label: 'Packing List', url: 'https://packinglist.monsterball.com.au/' },
        { label: 'Bookings Email', url: 'https://mail.google.com/mail/u/?authuser=bookings@monsterball.com.au' },
        { label: 'Enquiries Email', url: 'https://mail.google.com/mail/u/?authuser=enquiries@monterball.com.au' },
        { label: 'Accounts Email', url: 'https://mail.google.com/mail/u/?authuser=accounts@monsterball.com.au' },
        { label: 'Log Books', url: 'https://app.connecteam.com/index.html#/index/knowledge-base/knowledgeBase/2230448' },
        { label: 'Quickbooks', url: 'https://app.qbo.intuit.com/app/homepage?useNeo=true' },
        { label: 'Connecteam', url: 'https://app.connecteam.com/index.html#/index/dashboard/dashboard?lpid=dashboard' },
        { label: 'Monday', url: 'https://monsterball-company.monday.com/workspaces/-1' },
        //{ label: 'Marketing Report', url: '' },
        { label: 'Margin Calculator', url: 'https://docs.google.com/spreadsheets/d/1AeYMFBPvQNmVxs_k_FoGorPL5FVIgzHWfflbHSKCs18/edit' },
        { label: 'MB Website', url: 'https://monsterball.com.au/' },
        { label: 'Inflatable Office', url: 'https://rental.software/account/#/leads' },
    ];

    return (
        <Container fluid className="col-xs-12 col-xl-9 mx-auto p-0 d-flex min-vh-100 flex-column">
            <Container fluid className="bg-white border-bottom">
                <Navbar className="navigation col-xs-12 col-md-11 col-xl-9 mx-auto px-0" expand="lg">          
                    <Navbar.Brand href="/" className="mx-auto dashboard-main">
                        <img
                            alt="Monsterball"
                            src={logo}
                            className="logo" />
                            <span className="ml-3 dashboard-header">Dashboard</span>
                    </Navbar.Brand>
                </Navbar>
            </Container>
            <Container fluid className="bg-white pt-4 flex-fill fill d-flex">
                <div className="col-xs-12 col-md-11 col-xl-9 mx-auto px-0">
                    <div className="dashboard-links container d-flex flex-wrap align-items-center justify-content-center">
                        {links.map((item, index) => {
                            return (
                                <div>
                                    <a href={item.url} class="m-2 link" target="_blank">{item.label}</a>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </Container>
        </Container>        
    );
}
