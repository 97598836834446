import React from 'react';

import { v4 as uuidv4 } from 'uuid';

export default function EquipmentTitleRow({children}) {
    return (
        <tr className="equipment-title" key={uuidv4()}>
            <td colSpan="2">
                <h5 data-testid="TitleRow" className="mb-0">{children}</h5>
            </td>
        </tr>
    );
}
