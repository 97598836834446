import React from 'react';
import {  
  BrowserRouter as Router,Route,
  Switch} from 'react-router-dom';

import PackingListRoute  from './PackingListRoute';
import { Dashboard } from './pages/Dashboard/Dashboard';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/PackingList.scss';

function App() {
  
  return (
    <Router>
    <Switch>
      <Route exact path="/dashboard/" component={Dashboard} />
      <Route path='/' ><PackingListRoute /></Route>
    </Switch>
    </Router>
  );
}

export default App;
