import React, { forwardRef } from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import { ListItemLoadingAlert, ListItemInfoAlert} from '../../components/alerts/Alerts';
import { queryData } from '../../utils/FetchUtils';
import { getDateKey } from '../../utils/DateUtils';

import PackingListLink from './PackingListLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';
export default function SearchPackingLists({setError, ...props}) {
    const ref = React.createRef();

    const [searchRun, setSearchRun] = React.useState(false);
    const [loadingSearchedLists, setLoadingSearchedLists] = React.useState(false);
    const [searchDate, setSearchDate] = React.useState(null);
    const [searchedPackingLists, setSearchedPackingLists] = React.useState([]);

    const attributesToGet =  ["Id", "LoadingDate", "Vehicle", "Trailer", "Complete", "CompletedDate"];
    
    const DateSearch = forwardRef((props, ref) => (
        <InputGroup ref={ref}>
            <FormControl data-testid="SearchDateInput" onClick={props.onClick} value={props.value} onChange={props.onChange} disabled={props.disabled} placeholder={props.placeholderText}/>
            <InputGroup.Append>
                <Button data-testid="SearchPackingListsButton" disabled={searchDate === null} onClick={searchPackingLists} variant="info"><FontAwesomeIcon icon={faSearch} /></Button>
            </InputGroup.Append>
        </InputGroup>
    ));

    const searchPackingLists = async () => {
        setLoadingSearchedLists(true);
        setError(null);
        setSearchRun(true);
        try {
            var packingLists = [];
            var dateKey = getDateKey(new Date(searchDate));

            var responseData = await queryData("PackingList", "DateIndex", "LoadingDateKey = :dateKeyValue AND LoadingDate = :searchDateValue", null, 
                {"dateKeyValue": dateKey, "searchDateValue": new Date(searchDate).toISOString()}, attributesToGet);

            if (responseData.isError) {
                setLoadingSearchedLists(false);
                setError(responseData.error);
                return;
            }

            packingLists.push(...responseData);

            setSearchedPackingLists(packingLists);
            setLoadingSearchedLists(false);
        } catch (ex) {
            setError("Unable to find Packing List information. The following error was returned: " + ex.message);      
        }
    }

    return (
        <Card {...props}>
            <Card.Body>
                <Card.Title>
                    Search Jobs By Date
                </Card.Title>
                <Card.Subtitle>
                    <DatePicker 
                        name="SearchDate" 
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Search Date"                                
                        customInput={<DateSearch ref={ref}/>}
                        selected={Date.parse(searchDate)} 
                        onChange={date => {
                            var utcDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()));
                            setSearchDate(utcDate.toISOString());
                        }} />
                </Card.Subtitle>
            </Card.Body>
            <ListGroup className="list-group-flush packing-list-group">
                <ListItemLoadingAlert data-testid="LoadingAlert" isVisible={loadingSearchedLists}>Loading Packing Lists</ListItemLoadingAlert>
                <ListItemInfoAlert data-testid="NoResultsAlert" isVisible={searchedPackingLists.length === 0 && searchRun && !loadingSearchedLists}>No Packing Lists found for the selected date.</ListItemInfoAlert>
                <ListItemInfoAlert data-testid="InfoAlert" isVisible={!searchRun}>Select a date to search for Packing Lists.</ListItemInfoAlert>
                            
                {searchedPackingLists.map((item, index) => {
                    return (
                        <PackingListLink key={uuidv4()} packingList={item}/>
                    );
                })}
            </ListGroup>
        </Card>
    );
}
