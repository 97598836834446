import React, { forwardRef } from 'react';
import {EquipmentItemRow} from '../components/EquipmentItemRow';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

export default function AuditableEquipmentItemRow({item, onClick, nameKey = "Name"}) {
    return (
        <OverlayTrigger key={uuidv4()} trigger={item.selected ? "click" : ""} placement="bottom" rootClose={true} overlay={<AuditPopover item={item} />}>
            <EquipmentItemRow key={uuidv4()} item={item} onClick={onClick} nameKey={nameKey}/>
        </OverlayTrigger>
    );
}

const AuditPopover = forwardRef((props, ref) => (
    <Popover id="audit-popover" {...props} ref={ref}>
        <Popover.Title as="h3">Audit</Popover.Title>
        <Popover.Content>
            <dl className="mb-0">
                <dt>Checked By</dt>
                <dd>{props.item.SelectedBy}</dd>
                <dt>Checked On</dt>
                <dd>{moment(props.item.SelectedDate).format("D MMM YYYY hh:mm:ss A")}</dd>
            </dl>
        </Popover.Content>
    </Popover>    
));
