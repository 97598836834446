import React, { useEffect } from 'react';

import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import GroupBy from 'lodash/groupBy';
import UniqBy from 'lodash/uniqBy';
import Map from 'lodash/map';
import Filter from 'lodash/filter';

import moment from 'moment';

import { ListItemLoadingAlert, ListItemInfoAlert} from '../../components/alerts/Alerts';
import { queryData } from '../../utils/FetchUtils';
import { getDateWithoutTime, getDateKey } from '../../utils/DateUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export default function UpcomingTransferLists({setError}) {
    const [upComingTransfers, setUpComingTransfers] = React.useState([]);
    const [upcomingTransferStaff, setUpcomingTransferStaff] = React.useState([]);
    const [transferStaffFilter, setTransferStaffFilter] = React.useState("all");
    const [loadingUpcomingTransfers, setLoadingUpcomingTransfers] = React.useState(false);

    const attributesToGet =  ["Id", "TransferDate", "FromVehicleExtraRegistration", "DisplayFromVehicleId", "DisplayFromVehicleRegistration", "DisplayToVehicleRegistration", "DisplayTransferStaff", "Complete", "CompletedDate"];

    const refreshUpcomingTransfers = React.useCallback(async () => {
        var filterValue = document.getElementById('TransferStaffFilter').value;
        loadUpcomingTransfers(filterValue);
    },[]);

    const loadUpcomingTransfers = React.useCallback(async (staffFilter) => {
        setLoadingUpcomingTransfers(true);
        setError(null);
    
        try {
            var transfers = []; 
            var currentDate = getDateWithoutTime();
            var twoDaysAgoDate = new Date(moment(currentDate).subtract(2,'d'));     
            var twoDaysDateKey = getDateKey(twoDaysAgoDate);

            var oneWeekDate = new Date(moment(currentDate).add(7,'d'));            
            var nextDateKey = getDateKey(oneWeekDate);

            var responseData = await queryData("TransferList", "DateIndex", "TransferDateKey = :dateKeyValue AND TransferDate BETWEEN :fromDateValue AND :toDateValue", null, 
                {"dateKeyValue": twoDaysDateKey, "fromDateValue": twoDaysAgoDate.toISOString(), "toDateValue": oneWeekDate.toISOString()}, attributesToGet);
            if (responseData.isError) {
                setLoadingUpcomingTransfers(false);
                setError(responseData.error);
                return;
            }

            transfers.push(...responseData);

            if (oneWeekDate.getMonth() !== twoDaysAgoDate.getMonth()){
                // next week goes to the next month, so get next month's lists just in case

                var nextPeriodData = await queryData("TransferList", "DateIndex", "TransferDateKey = :dateKeyValue AND TransferDate BETWEEN :fromDateValue AND :toDateValue", null, 
                    {"dateKeyValue": nextDateKey, "fromDateValue": twoDaysAgoDate.toISOString(), "toDateValue": oneWeekDate.toISOString()}, attributesToGet);
                if (nextPeriodData.isError) {
                    setLoadingUpcomingTransfers(false);
                    setError(nextPeriodData.error);
                    return;
                }

                transfers.push(...nextPeriodData);
            }

            if (transfers.length !== 0) {
                var uniqueStaff = UniqBy(Map(transfers, "DisplayTransferStaff"));
                setUpcomingTransferStaff(uniqueStaff);

                if (staffFilter !== "all") {
                    transfers = Filter(transfers, function(item) { 
                        return item.DisplayTransferStaff === staffFilter;
                    });
                }

                // check from the filter
                if (transfers.length !== 0) {
                    var groupedLists = GroupBy(transfers, function (item) {
                        if (item.DisplayTransferStaff !== 'undefined') {
                            return item.DisplayTransferStaff;
                        } else {
                            return "any";
                        }
                    });

                    Object.keys(groupedLists).forEach(function(staff) {
                        groupedLists[staff] = GroupBy(groupedLists[staff], function(item) {
                            return item.TransferDate;
                        });

                        // remove duplicate from vehicle
                        Object.keys(groupedLists[staff]).forEach(function(transferDate) {
                            groupedLists[staff][transferDate] = UniqBy(groupedLists[staff][transferDate],  "DisplayFromVehicleId");
                        });

                    });

                    console.log(groupedLists);
                    setUpComingTransfers(groupedLists);
                } else {
                    setUpComingTransfers([]);
                }

            } else {
                setUpComingTransfers([]);
                setUpcomingTransferStaff([]);
            }
            setLoadingUpcomingTransfers(false);
        } catch (ex) {
            setError("Unable to load Transfer information. The following error was returned: " + ex.message);      
            setLoadingUpcomingTransfers(false);
        }
    }, []);

    const filterTransfersByStaff = (event) => {
        var selectedValue = event.target.value;
        setTransferStaffFilter(selectedValue);
    }

    useEffect(() => {
        loadUpcomingTransfers(transferStaffFilter);
    }, [transferStaffFilter]);

    return (
    <Card>
        <Card.Body>
            <Card.Title>
                Upcoming Transfers
                <span role="button" className="float-right"><FontAwesomeIcon data-testid="RefreshTransfers" onClick={refreshUpcomingTransfers} icon={faSync}  /></span>
            </Card.Title>
            <Card.Subtitle className="text-muted d-flex justify-content-between align-items-center">
                Next 7 Days
                <select data-testid="StaffFilter" className="text-right" onChange={filterTransfersByStaff} id="TransferStaffFilter"> 
                    <option key={uuidv4()} value="all">All Staff...</option>
                    {upcomingTransferStaff.map((item, index) => {
                        return <option key={uuidv4()} value={item}>{item}</option>
                    })}
                </select>
            </Card.Subtitle>
        </Card.Body>
        <ListGroup className="list-group-flush packing-list-group">
            <ListItemLoadingAlert data-testid="LoadingTransfersAlert" isVisible={loadingUpcomingTransfers}>Loading Transfers</ListItemLoadingAlert>
            <ListItemInfoAlert data-testid="NoTransfersAlert" isVisible={upComingTransfers.length === 0 && !loadingUpcomingTransfers}>No Transfers found for the selected date.</ListItemInfoAlert>
                        
            {Object.keys(upComingTransfers).map((transferStaff) => {
                return (
                    <span key={uuidv4()}>
                        <a href={"/transferlists/list/?transferstaff=" + transferStaff} className="list-group-item list-group-item-action packinglist-transfer-staff border-0">
                            <strong data-testid="TransferStaff">{transferStaff}</strong>
                        </a>
                        {Object.keys(upComingTransfers[transferStaff]).map((transferDate) => {
                            return (
                            <span key={uuidv4()}>
                                <a className="packinglist-date border-0 list-group-item list-group-item-action" href={"/transferlists/list/?transferstaff=" + transferStaff + "&transferdate=" + transferDate}>
                                    <strong>{moment(transferDate).format("dddd D MMM YYYY")}</strong>
                                </a>
                                {upComingTransfers[transferStaff][transferDate].map((item, index) => {
                                    return (
                                    <a data-testid="TransferListLink" key={uuidv4()} href={"/transferlists/list/?transferstaff=" + transferStaff + "&transferdate=" + transferDate + "&vehicleid=" + item.DisplayFromVehicleId} className="border-0 list-group-item d-flex justify-content-between align-items-center list-group-item-action packinglist-item">
                                        <span className="mr-auto" data-testid="FromRegistration">
                                            {item.FromVehicleExtraRegistration && (item.FromVehicleExtraRegistration + " + ")}
                                            {item.DisplayFromVehicleRegistration}
                                        </span>
                                        {item.Complete &&
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={<Tooltip>{moment(item.CompletedDate).format("ddd DD/MM/YYYY hh:mm:ss A")}</Tooltip>}>
                                            <Badge data-testid="CompleteBadge" className="ml-1 mr-1" variant="success">Complete</Badge>
                                        </OverlayTrigger>}
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </a>
                                    );
                                })}
                            </span>)
                        })}
                    </span>
                );
            })}
        </ListGroup>
    </Card>
    );
}
