import React from 'react';

import Alert from 'react-bootstrap/Alert';
import ListGroupItem from 'react-bootstrap/ListGroupItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

export function ErrorAlert({isVisible = true, onClose, cssClass, children, ...props}) {
    return (
        isVisible ? <Alert {...props} variant="danger" className={"text-center " + (cssClass ? cssClass : '')} onClose={() => onClose(false)} dismissible>{children}</Alert> : null
    );
}

export function ListItemErrorAlert({isVisible = true, cssClass, children, ...props}) {
    return (
        isVisible ? <ListGroupItem {...props} className={"list-group-item-danger text-center " + (cssClass ? cssClass : '')}>{children}</ListGroupItem> : null
    );
}

export function InfoAlert({isVisible = true, onClose, cssClass, children, ...props}) {
    return (
        isVisible ? <Alert {...props} variant="info" className={"text-center " + (cssClass ? cssClass : '')} onClose={() => onClose(false)} dismissible={onClose}>{children}</Alert> : null
    );
}

export function ListItemInfoAlert({isVisible = true, cssClass, children, ...props}) {
    return (
        isVisible ? <ListGroupItem {...props} className={"list-group-item-info text-center " + (cssClass ? cssClass : '')}>{children}</ListGroupItem> : null
    );
}

export function LoadingAlert({isVisible = true, cssClass, children, ...props}) {
    return (
        isVisible ? <Alert {...props} variant="info" className={"text-center " + (cssClass ? cssClass : '')}><FontAwesomeIcon icon={faSync} className="mr-2" spin />&nbsp;{children}</Alert> : null
    );
}

export function ListItemLoadingAlert({isVisible = true, cssClass, children, ...props}) {
    return (
        isVisible ? <ListGroupItem {...props} className={"list-group-item-info text-center " + (cssClass ? cssClass : '')}><FontAwesomeIcon icon={faSync} className="mr-2" spin />&nbsp;{children}</ListGroupItem> : null
    );
}
