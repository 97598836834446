/* istanbul ignore file */

import React from 'react';
import { Auth } from 'aws-amplify'
import {
    withRouter,
    Route,
    Redirect
} from 'react-router-dom'

export class PrivateRoute extends React.Component {
    state = {
        loaded: false,
        isAuthenticated: false
    }
    componentDidMount() {
        this.authenticate();
        this.unlisten = this.props.history.listen(() => {
            Auth.currentAuthenticatedUser()
                .then(user => console.log('user: ', user))
                .catch((ex) => {
                    if (this.state.isAuthenticated) 
                        this.setState({ isAuthenticated: false })
                });
        });
    }
    componentWillUnmount() {
        this.unlisten();
    }
    authenticate() {
        Auth.currentAuthenticatedUser()
            .then((user) => {
                this.setState({ loaded: true, isAuthenticated: true })
            })
            .catch((ex) => {
                if (ex === "not authenticated"){
                    this.props.history.push(Auth.federatedSignIn());
                }
            });
    }
    render() {
        const { component: Component, ...rest } = this.props
        const { loaded , isAuthenticated} = this.state
        if (!loaded) return null
        return (
        <Route
            {...rest}
            render={props => {
            return isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={Auth.federatedSignIn()} />
            )
            }}
        />
        )
    }
}

PrivateRoute = withRouter(PrivateRoute)