const moment = require('moment')

export const getDateWithoutTime = (date = new Date()) => {
    return new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()));
}

export const getDateKey = (date = new Date()) => {    
    return date.getFullYear() + "" + (date.getMonth() + 1);
}

export const getNextDateKey = (date = new Date()) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    if (month === 12){
        month = 1;
        year++;
    }else{
        month++;
    }
    return year + "" + month;
}

/* istanbul ignore next */
export const getMondayDate = () => {
    return moment().startOf('isoweek');
}