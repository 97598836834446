import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

export default function LoaderButton({
    isLoading,
    className = "",
    disabled = false,
    ...props
}) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}
            >
            {isLoading && <FontAwesomeIcon data-testid="LoadingIcon" icon={faSync} className="fa-spin mr-2" />}
            {props.children}
        </Button>
    );
}