import React from 'react';

import moment from 'moment';

import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export default function PackingListLink({packingList}) {

    return (
        <a data-testid="PackingListLink" key={uuidv4()} href={"/packinglists/view/" + packingList.Id} className="border-0 list-group-item d-flex justify-content-between align-items-center list-group-item-action packinglist-item">
            <span className="mr-auto" data-testid="VehicleDetails">
                {packingList.Vehicle.Registration}
                {packingList.Trailer && (" + " + packingList.Trailer.Registration)}
            </span>
            {packingList.Complete &&
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{moment(packingList.CompletedDate).format("ddd DD/MM/YYYY hh:mm:ss A")}</Tooltip>}>
                    <Badge data-testid="CompleteBadge" className="ml-1 mr-1" variant="success">Complete</Badge>
                </OverlayTrigger>}
            <FontAwesomeIcon icon={faChevronRight} />
        </a>
    );
}
