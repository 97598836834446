import React from 'react';

export default function FlexibleHeader({left, middle, right}) {
    return (
        <div className="flexible-header">
            <div data-testid="left" className="header-item d-none d-xl-flex">
                {left}
            </div>
            <div data-testid="middle" className="header-item pl-md-4 pl-xl-0">
                {middle}
            </div>
            <div data-testid="right" className="header-item">
                {right}
            </div>
        </div>
    );
}