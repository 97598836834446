import React from 'react';

import Button from 'react-bootstrap/Button';

import FlexibleHeader from "./FlexibleHeader";
import AuditableEquipmentItemRow from './AuditableEquipmentItemRow';
import {getCurrentUserEmail} from '../utils/UserUtils';

import FindKey from 'lodash/findKey';
import { v4 as uuidv4 } from 'uuid';

export default function ViewEquipmentList({data, equipmentList, selector, title, savePackingList, setData, nameKey = "Name", overrideQuantity = false, editable=true, children}) {

    const selectAllEquipment = () => {
        Array.from(equipmentList).forEach((equipmentItem) => {
            selectEquipmentItem(equipmentItem.Id, true);
        });
    }

    const selectEquipmentItem = async (equipmentKey, forceSelected = false) => {
        if (!editable) return;
        
        var splitSelector = selector.split(".");
        var item = data;
        splitSelector.forEach((x)=>{
            item = item[x];
        });

        if (!item[equipmentKey]) {
            equipmentKey = FindKey(item, function(x) { return x.Id === equipmentKey; });
            if (!item[equipmentKey]) return;
        };

        item[equipmentKey].selected = forceSelected ? true : !item[equipmentKey].selected;    
        var key = selector + "." + equipmentKey;
        var user = await getCurrentUserEmail();
        savePackingList({
            [key + ".selected"]: item[equipmentKey].selected,
            [key + ".SelectedDate"]: item[equipmentKey].selected ? new Date(): null,
            [key + ".SelectedBy"]: item[equipmentKey].selected ? user : null
        });

        if (data.Complete && !item[equipmentKey].selected) { 
            data.Complete = false;
            savePackingList({
                "Complete": false,
                "CompletedDate": '',
                "CompletedBy": ''
            });  
        }
        setData({...data});
    };

    return (
        
        equipmentList.length > 0 &&
            <React.Fragment>
                {title &&
                <tr className="equipment-title" key={uuidv4()}>
                    <td colSpan="2">
                        <FlexibleHeader 
                            middle={<h5 className="mb-0">{title}</h5>} 
                            right={<Button data-testid="SelectAll" variant="secondary" size="sm" onClick={()=>selectAllEquipment()}>Select All</Button>} />
                            {children}
                    </td>
                </tr>}
                {Array.from(equipmentList).map((item) => {
                    return (overrideQuantity || item.Quantity > 0) && 
                        <AuditableEquipmentItemRow key={uuidv4()} item={item} onClick={()=>selectEquipmentItem(item.Id)} nameKey={nameKey} />
                })}
            </React.Fragment>
    )
}
