import React, { useEffect } from 'react';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

import GroupBy from 'lodash/groupBy';
import OrderBy from 'lodash/orderBy';

import moment from 'moment';

import { ListItemLoadingAlert, ListItemInfoAlert} from '../../components/alerts/Alerts';
import { getPagedData } from '../../utils/FetchUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export default function VehicleKits({setError}) {
    const [loadingVehicles, setLoadingVehicles] = React.useState(false);
    const [vehicles, setVehicles] = React.useState([]);

    const attributesToGet =  ["Id", "Registration", "LastCheckedDate", "IsTrailer"];

    const loadVehicles = React.useCallback(async () => {
        setLoadingVehicles(true);
        setError(null);
    
        try {
            var allVehicles = await getPagedData("Vehicle", attributesToGet);
            if (allVehicles.isError) {
                setError(allVehicles.error);
                setLoadingVehicles(false);
                return;
            }
            allVehicles = OrderBy(allVehicles, "Registration");

            if (allVehicles.length !== 0) {
                var groupedVehicles = GroupBy(allVehicles, function (item) {
                    return item.IsTrailer ? "Trailers" : "Vehicles";
                });

                delete groupedVehicles["Trailers"];
                setVehicles(groupedVehicles);
                console.log(groupedVehicles);
            } else {
                setVehicles([]);
            }
            setLoadingVehicles(false);
        } catch (ex) {
            setLoadingVehicles(false);
            setError("Unable to load Vehicle information. The following error was returned: " + ex.message);      
        }
    }, []);

    useEffect(() => {
        loadVehicles();
    }, []);
    return (
        <Card>
            <Card.Body>
                <Card.Title className="m-0">Vehicle Kits</Card.Title>
            </Card.Body>
            <ListGroup className="list-group-flush packing-list-group">
                <ListItemLoadingAlert data-testid="LoadingVehiclesAlert" isVisible={loadingVehicles}>Loading Vehicles</ListItemLoadingAlert>
                <ListItemInfoAlert data-testid="NoVehiclesAlert" isVisible={vehicles.length === 0 && !loadingVehicles}>No Vehicles found.</ListItemInfoAlert>
    
                {Object.keys(vehicles).map((type) => {
                    return (
                        <span key={uuidv4()}>
                            {vehicles[type].map((item, index) => {
                                return (
                                    <a data-testid="VehicleKitItem" key={uuidv4()} href={"/vehicles/kit/" + item.Id} className="border-0 list-group-item list-group-item-action packinglist-item">
                                        <div className="d-flex justify-content-between align-items-center w-100">
                                            <div className="d-flex flex-column">
                                                {item.Registration}
                                                <small>Last Checked: {item.LastCheckedDate ? moment(item.LastCheckedDate).format("ddd DD/MM/YYYY hh:mm:ss A") : "Never"}</small>
                                            </div>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </div>                                            
                                    </a>
                                );
                            })}
                        </span>
                    );
                })}
            </ListGroup>
        </Card>
    );
}
