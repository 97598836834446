import React, { useEffect } from 'react';

import LoaderButton from '../../components/LoaderButton';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import OrderBy from 'lodash/orderBy';
import Filter  from 'lodash/filter';
import Find  from 'lodash/find';
import DeepClone  from 'lodash/cloneDeep';
import Map from 'lodash/map';

import EventItem from "./EventItem";

import RequiredFormLabel from "../../components/form/RequiredFormLabel";
import DraggableList from "../../components/DraggableList";
import useCustomForm from '../../components/Form'
import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { getDataByKey, batchGetDataByKey, saveData, getPagedData, queryData, deleteRecord } from '../../utils/FetchUtils'
import {getDateKey} from '../../utils/DateUtils';
import { Draggable } from "react-beautiful-dnd";

import { v4 as uuidv4 } from 'uuid';

import { useRouter } from "../../components/Router";

export function EditPackingList() {
        
    const eventData = {
        EventName: '',
        EventDate: new Date(),
        Comments: '',
        Equipment: {},
        EquipmentToLoad: {},
        EventOrder: 0,
        CollapseEquipment: true
    }

    const vehicleData = {
        VehicleKit: {
            Pegs: {Id: uuidv4(), Name: "Pegs", Quantity: 0},
            TenAmpLeads: {Id: uuidv4(), Name: "10 Amp Leads", Quantity: 0},
            Hoses: {Id: uuidv4(), Name: "Hoses", Quantity: 0},
            OneHPBlowers: {Id: uuidv4(), Name: "1.5HP Blowers", Quantity: 0},
            TwoHPBlowers: {Id: uuidv4(), Name: "2HP Blowers", Quantity: 0}
        },
        ExtraEquipmentKit: {},
        CabinEquipmentKit: {}
    }

    const initialData = {
        Id: uuidv4(),
        LoadingDate: '',
        Comments: '',
        VehicleId: '',
        TrailerId: '',
        TransferStaff: '',
        TrolleyRequired: false,
        ExtraBlower: false,
        Events: {},

        EquipmentToLoad: {
            Pegs: {Id: uuidv4(), Name: "Pegs", Quantity: 0},
            TenAmpLeads: {Id: uuidv4(), Name: "10 Amp Leads", Quantity: 0},
            Hoses: {Id: uuidv4(), Name: "Hoses", Quantity: 0},
            Sandbags: {Id: uuidv4(), Name: "Sandbags", Quantity: 0},
            OneHPBlowers: {Id: uuidv4(), Name: "1.5HP Blowers", Quantity: 0},
            TwoHPBlowers: {Id: uuidv4(), Name: "2HP Blowers", Quantity: 0}
        },

        Vehicle: DeepClone(vehicleData),
        Trailer: DeepClone(vehicleData)
    }

    const [data, setData] = React.useState(initialData);
    const [vehicles, setVehicles] = React.useState([]);
    const [trailers, setTrailers] = React.useState([]);
    const [equipment, setEquipment] = React.useState([]);
    const [allowTrailer, setAllowTrailer] = React.useState(false);
    const [trailerSelected, setTrailerSelected] = React.useState(false);

    const [loadingEquipment, setLoadingEquipment] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");

    const router = useRouter();
    const id = React.useRef(undefined);

    const submitForm = React.useCallback(async (values) => {
        setSaving(true);
        setError(null);
        setInfo(null);

        var data = DeepClone(values.data);
        if (!data.LoadingDate) {
            setSaving(false);   
            setError("Please enter a Loading Date.");
            return;
        }

        if (!data.VehicleId || data.VehicleId === "Vehicle...") {
            setSaving(false);   
            setError("Please select a Vehicle.");
            return;
        }
        
        if (Object.keys(data.Events).length === 0) {
            setSaving(false);   
            setError("Please add at least one event.");
            return;
        }

        var vehicleDetails = await getDataByKey("Vehicle", "Id", data.VehicleId);
        if (vehicleDetails === null) {
            setError("Unable to load details for Vehicle " + data.VehicleId);
            setSaving(false);   
            return;
        }

        data.Vehicle = {...data.Vehicle, ...vehicleDetails};
        data.VehicleRegistration = vehicleDetails.Registration;

        setupVehicleEquipment(data.Vehicle, vehicleDetails);

        if (data.TrailerId) {
            var trailerDetails = await getDataByKey("Vehicle", "Id", data.TrailerId);
            if (trailerDetails === null) {
                setError("Unable to load details for Vehicle " + data.TrailerId);
                setSaving(false);   
                return;
            }
            data.Trailer = {...data.Trailer, ...trailerDetails};
            data.TrailerRegistration = trailerDetails.Registration;

            /* istanbul ignore else */
            if (!data.Trailer.VehicleKit) {
                data.Trailer = {...data.Trailer, ...vehicleData};
            }
            setupVehicleEquipment(data.Trailer, trailerDetails);
        } else {
            data.Trailer = null;
            data.TrailerRegistration = null;
        }

        Object.keys(data.EquipmentToLoad).forEach((key) => {
            data.EquipmentToLoad[key].Quantity = 0;
        });

        var eventKeys = Object.keys(data.Events);
        var uniqueEquipment = {};
        for (var i = 0; i < eventKeys.length; i++) {
            var event = data.Events[eventKeys[i]];

            // only needed for UI so remove it before saving
            delete event.Collapsed;
            delete event.CollapseEquipment;

            Object.keys(event.EquipmentToLoad).forEach((key) => {
                event.EquipmentToLoad[key].Quantity = 0;
            });

            var equipmentIds = Map(event.Equipment, "Id");
        
            var equipmentDetails = await batchGetDataByKey("Equipment", "Id", equipmentIds);
            if (equipmentDetails === null){
                setError("Unable to load Equipment Details.");
                setSaving(false);   
                return;
            }

            var powerRequired = 0;
            Object.keys(event.Equipment).forEach((equipmentKey) => {
                var equipment = event.Equipment[equipmentKey];
                var dbEquipment = Find(equipmentDetails.Equipment, function(x) { return x.Id === equipment.Id; });
                equipment =  {...equipment, ...dbEquipment};
            
                // negative order numbers to force the important equipment before the extras
                if (equipment.SandbagRequired) {
                    addEquipmentQuantity(event.EquipmentToLoad, "Sandbags", equipment.Sandbags, -6, true);
                } else {
                    addEquipmentQuantity(event.EquipmentToLoad, "Pegs", equipment.Pegs, -5);
                }

                if (equipment.GeneratorRequired) {
                    powerRequired += (Number(equipment.OneHPBlowers) * 1.5); // 1HP blowers are actually 1.5HP
                    powerRequired += (Number(equipment.TwoHPBlowers) * 2);
                }

                if (equipment.AddToTrailer) {
                    equipment.VehicleId = data.TrailerId;
                } else {
                    equipment.VehicleId = data.VehicleId;
                }

                addEquipmentQuantity(event.EquipmentToLoad, "TenAmpLeads", equipment.TenAmpLeads, -4);
                addEquipmentQuantity(event.EquipmentToLoad, "Hoses", equipment.Hoses, -3);
                addEquipmentQuantity(event.EquipmentToLoad, "OneHPBlowers", equipment.OneHPBlowers, -2);
                addEquipmentQuantity(event.EquipmentToLoad, "TwoHPBlowers", equipment.TwoHPBlowers, -1);
                
                if (equipment.ExtraEquipment) {
                    equipment.ExtraEquipment.forEach(extras => {
                        addEquipmentQuantity(event.EquipmentToLoad, extras.Name, extras.Quantity, extras.Order);
                    });
                }
                event.Equipment[equipmentKey] = equipment;

                /* istanbul ignore else */
                if (!uniqueEquipment[equipment.Id]) {
                    uniqueEquipment[equipment.Id] = equipment;
                }
            });

            if (powerRequired > 0) {
                // Generators are rated for 5.5HP. So get total generators required and ceiling it for an integer number
                var totalGeneratorsRequired = powerRequired / 5.5;
                var generatorsRequired = Math.ceil(totalGeneratorsRequired);                    
                addEquipmentQuantity(event.EquipmentToLoad, "Generators", generatorsRequired, -7, true);
            }
            
            Object.keys(event.EquipmentToLoad).forEach((key) => {
                if (!data.EquipmentToLoad[key]) {
                    addEquipmentQuantity(data.EquipmentToLoad, key, event.EquipmentToLoad[key].Quantity, event.EquipmentToLoad[key].Order, event.EquipmentToLoad[key].IsImportant);
                } else {
                    if (key === "OneHPBlowers" || key === "TwoHPBlowers") {
                        data.EquipmentToLoad[key].Quantity += event.EquipmentToLoad[key].Quantity;
                    }
                    else {
                        data.EquipmentToLoad[key].Quantity = Math.max(data.EquipmentToLoad[key].Quantity, event.EquipmentToLoad[key].Quantity);
                    }
                    data.EquipmentToLoad[key].Order = Math.min(data.EquipmentToLoad[key].Order ?? 0, event.EquipmentToLoad[key].Order);
                    data.EquipmentToLoad[key].IsImportant = data.EquipmentToLoad[key].IsImportant || event.EquipmentToLoad[key].IsImportant;
                }
            });
        }

        if (data.TrolleyRequired) {
            addEquipmentQuantity(data.EquipmentToLoad, "Trolley", 1, -8);
        }

        if (data.ExtraBlower) {
            addEquipmentQuantity(data.Vehicle.ExtraEquipmentKit, "1.5HP Blowers", 1, -1, true);
        }

        Object.keys(data.EquipmentToLoad).forEach((key) => {
            var equipment = data.EquipmentToLoad[key];
            if (data.Vehicle.VehicleKit[key] && equipment.Quantity > 0) {
                equipment.Quantity -= data.Vehicle.VehicleKit[key].Quantity;
            }
            if (data.Vehicle.ExtraEquipmentKit[key] && equipment.Quantity > 0) {
                equipment.Quantity -= data.Vehicle.ExtraEquipmentKit[key].Quantity;
            }
            if (data.Vehicle.CabinEquipmentKit[key] && equipment.Quantity > 0) {
                equipment.Quantity -= data.Vehicle.CabinEquipmentKit[key].Quantity;
            }
            if (data.Trailer && data.Trailer.VehicleKit[key] && equipment.Quantity > 0) {
                equipment.Quantity -= data.Trailer.VehicleKit[key].Quantity;
            }
            if (data.Trailer && data.Trailer.ExtraEquipmentKit[key] && equipment.Quantity > 0) {
                equipment.Quantity -= data.Trailer.ExtraEquipmentKit[key].Quantity;
            }
            if (data.Trailer && data.Trailer.CabinEquipmentKit[key] && equipment.Quantity > 0) {
                equipment.Quantity -= data.Trailer.CabinEquipmentKit[key].Quantity;
            }
        });

        data.LoadingDateKey = getDateKey(new Date(data.LoadingDate));
        data.CreatedDate = new Date();
        data.Complete = false;
        data.CompletedBy = null;
        data.CompletedDate = null;

        try {
            var listEquipment = await queryData("PackingListEquipment", null, "PackingListId = :packingListValue", null, 
            {"packingListValue": data.Id}, ["PackingListId", "EquipmentId"]);

            await listEquipment.forEach(async(existingEquipment) => {
                /* istanbul ignore else */
                if (!Object.keys(uniqueEquipment).includes(existingEquipment.EquipmentId)) {
                    await deleteRecord("PackingListEquipment", existingEquipment.PackingListId, "PackingListId", existingEquipment.EquipmentId, "EquipmentId")
                }
            });

            await Object.keys(uniqueEquipment).forEach(async (equipmentId) => {
                var packingListEquipment = {
                    PackingListId: data.Id,
                    VehicleId: (uniqueEquipment[equipmentId].AddToTrailer && data.TrailerId) ? data.TrailerId : data.VehicleId,
                    AddToTrailer: uniqueEquipment[equipmentId].AddToTrailer,
                    EquipmentId: equipmentId,
                    Equipment: uniqueEquipment[equipmentId],
                    LoadingDate: data.LoadingDate,
                    LoadingDateKey: data.LoadingDateKey
                };
                packingListEquipment.EquipmentIdVehicleId = equipmentId + packingListEquipment.VehicleId;

                var listEquipmentResponse = await saveData("PackingListEquipment", "PackingListId", packingListEquipment, null, "EquipmentId");
                if (listEquipmentResponse.isError) {
                    setSaving(false);   
                    setError(listEquipmentResponse.error);
                    return;
                }
            });

            var saveResponse = await saveData("PackingList", "Id", data, process.env.REACT_APP_TRANSFER_TOPIC);
            if (saveResponse.isError) {
                setSaving(false);   
                setError(saveResponse.error);
                return;
            }

            setSaving(false);
            setInfo("Packing List has been saved successfully.");

            /* istanbul ignore next */
            setTimeout(()=>window.location.href = "/packinglists/view/" + values.data.Id, 1000);
        } catch (ex) {
            setSaving(false);
            setError("Unable to save Packing List. The following error was returned: " + ex.message);      
        }
    }, []);

    const setupVehicleEquipment = (vehicle, vehicleDetails) => {
        vehicle.VehicleKit.Pegs.Quantity = vehicleDetails.Pegs ? Number(vehicleDetails.Pegs) : 0;
        vehicle.VehicleKit.TenAmpLeads.Quantity = vehicleDetails.TenAmpLeads ? Number(vehicleDetails.TenAmpLeads) : 0;
        vehicle.VehicleKit.Hoses.Quantity = vehicleDetails.Hoses ? Number(vehicleDetails.Hoses) : 0; 
        vehicle.VehicleKit.OneHPBlowers.Quantity = vehicleDetails.OneHPBlowers ? Number(vehicleDetails.OneHPBlowers) : 0;
        vehicle.VehicleKit.TwoHPBlowers.Quantity = vehicleDetails.TwoHPBlowers ? Number(vehicleDetails.TwoHPBlowers) : 0;

        Object.keys(vehicle.ExtraEquipmentKit).forEach((key) => {
            vehicle.ExtraEquipmentKit[key].Quantity = 0;
        });

        convertEquipment(vehicle.ExtraEquipment, vehicle.ExtraEquipmentKit);
    
        Object.keys(vehicle.CabinEquipmentKit).forEach((key) => {
            vehicle.CabinEquipmentKit[key].Quantity = 0;
        });
        convertEquipment(vehicle.CabinEquipment, vehicle.CabinEquipmentKit);
        
    }

    const convertEquipment = (equipmentList, equipmentKitList) => {
        if (equipmentList) {
            equipmentList.forEach(extras => {
                /* istanbul ignore else */
                if (!equipmentKitList[extras.Name]) {
                    equipmentKitList[extras.Name] = {
                        Id: uuidv4(),
                        Name: extras.Name,
                        Quantity: 0,
                        Order: extras.Order
                    };
                }
                equipmentKitList[extras.Name].Order = extras.Order;
                equipmentKitList[extras.Name].Quantity += Number(extras.Quantity);
            });
        }
    };

    const addEquipmentQuantity = (equipmentList, name, quantity, order = 0, isImportant = false)=> {
        if (!equipmentList[name]) {
            equipmentList[name] = {
                Id: uuidv4(),
                Name: name,
                Quantity: 0
            };
        }
        equipmentList[name].Order = order;
        equipmentList[name].Quantity += Number(quantity);
        equipmentList[name].IsImportant = isImportant;
    }

    const fetchVehicleData = React.useCallback(async () => {
        try {
            var allVehicles = await getPagedData("Vehicle", ["Id", "Registration", "AllowTrailer", "IsTrailer"]);
            if (allVehicles.isError){
                setError(allVehicles.error);
                return;
            }
            allVehicles = OrderBy(allVehicles, "Registration");
            
            var vehicles = Filter(allVehicles, function(item) { return !item.IsTrailer; });
            var trailers = Filter(allVehicles, function(item) { return item.IsTrailer === 1; });

            setVehicles(vehicles);            
            setTrailers(trailers);

            console.log(vehicles);
        } catch (ex) {
            setError(ex.message);
        }
    }, []);

    const fetchEquipmentData = React.useCallback(async () => {
        setLoadingEquipment(true);
        try {
            var equipment = await getPagedData("Equipment", ["Id", "EquipmentName", "Categories"], null);
            if (equipment.isError){
                setError(equipment.error);
            }
            
            var orderedEquipment = OrderBy(equipment, "EquipmentName");

            setEquipment(orderedEquipment);
            setLoadingEquipment(false);
        } catch (ex) {
            setError(ex.message);
        }
    }, []);

    const {
        values,
        handleChange,
        handleSubmit,
        setValues
        } = useCustomForm({initialValues: data, onSubmit: submitForm});

    useEffect(() => {
        fetchVehicleData();
        fetchEquipmentData();
    }, [fetchVehicleData, fetchEquipmentData]);

    useEffect(() => {
        const fetchData = async () => {            
            if (router.query.id !== undefined) {
                id.current = router.query.id;
            }
            else {
                return;
            }
        
            setLoading(true);
            setError(null);
            setInfo(null);
    
            try {
                var responseData = await getDataByKey("PackingList", "Id", id.current);
                if (responseData.isError) {
                    setError(responseData.error);
                    setLoading(false);
                    return;
                }
                
                setData({...initialData, ...responseData });

                if (responseData.Vehicle.AllowTrailer) {
                    setAllowTrailer(true);
                } else {
                    setAllowTrailer(false);
                }

                if (responseData.TrailerId){
                    setTrailerSelected(true);
                } else {
                    setTrailerSelected(false);
                }

                setLoading(false);
                console.log(data);
            } catch (ex) {
                setError("Unable to load Packing List information. The following error was returned: " + ex.message);      
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        var selectedVehicle = Find(vehicles, function(item) { return item.Id === values.VehicleId; });
        if (selectedVehicle && selectedVehicle.AllowTrailer) {
            setAllowTrailer(true);
            setValues({'TrailerId': data.TrailerId});
        } else {
            setAllowTrailer(false);
            setValues({'TrailerId': ''});
        }
    }, [vehicles, values.VehicleId]);

    useEffect(() => {
        if (values.TrailerId) {
            setTrailerSelected(true);
        } else {
            setTrailerSelected(false);
        }
    }, [values.TrailerId]);

    const addEvent = (event) => {
        var events = {...values.Events};

        var newEvent = {...eventData};
        newEvent.Id = uuidv4();
        newEvent.EventDate = new Date(values.LoadingDate);
        newEvent.EventOrder = Object.keys(events).length;
        newEvent.Collapsed = false;
        newEvent.CollapseEquipment = false;

        events[newEvent.Id] = newEvent;
        setValues({ 'Events': events});
        console.log(values);
    };

    return (
        <div>            
            <Form data-testid="PackingListForm" onSubmit={handleSubmit} className="create-packing-list">
                <Row className="pb-3">
                    <Col xs="12" sm="6">
                        <h1>Create Packing List</h1>
                    </Col>
                    <Col xs="12" sm="6" className="text-sm-right mt-2">
                        {(!loadingEquipment && Object.keys(values.Events).length > 0) &&
                            <LoaderButton
                                isLoading={saving}
                                variant="primary" size="lg"
                                type="submit">Save Packing List</LoaderButton>}
                    </Col>
                </Row>

                <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Packing List</LoadingAlert>
                <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
                <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>
                {!loading && 
                <React.Fragment>
                    <Form.Row className="align-items-center">
                        <Form.Group as={Col} xs="12" md="4">
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <RequiredFormLabel>Vehicle</RequiredFormLabel>
                                    <Form.Control required as="select" name="VehicleId" data-testid="VehicleSelect"
                                        onChange={handleChange}
                                        value={values.VehicleId}>
                                        <option>Vehicle...</option>
                                        {vehicles.map((item, index) => {
                                            return <option data-testid="VehicleItem" key={item.Id} value={item.Id}>{item.Registration}{item.AllowTrailer ? ' (Allows Trailer)' : ''}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} xs="12" className="mb-0 mb-sm-3">
                                    <Form.Label>Trailer</Form.Label>
                                    <Form.Control required as="select" name="TrailerId" data-testid="TrailerSelect"
                                        onChange={handleChange}
                                        disabled={!allowTrailer}
                                        value={values.TrailerId}>
                                        <option>Trailer...</option>
                                        {trailers.map((item, index) => {
                                            return <option data-testid="TrailerItem" key={item.Id} value={item.Id}>{item.Registration}</option>
                                        })}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group as={Col} xs="12" md="4" className="align-self-start">
                            <Form.Row>
                                <Form.Group as={Col} xs="12">
                                    <RequiredFormLabel>Loading Date</RequiredFormLabel>
                                    <DatePicker className="form-control d-block"
                                        name="LoadingDate" 
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Loading Date" 
                                        minDate={new Date()}
                                        selected={Date.parse(values.LoadingDate)} 
                                        onChange={date => {
                                            var utcDate = new Date(Date.UTC(date.getFullYear(),date.getMonth(), date.getDate()));
                                            handleChange({target: {name: "LoadingDate", value: utcDate.toISOString()}});
                                        }} />
                                </Form.Group>
                                <Form.Group as={Col} xs="12">
                                    <Form.Check inline type="checkbox" label="Trolley Required?" id="TrolleyRequiredCheckbox"
                                        name="TrolleyRequired" 
                                        data-testid="TrolleyRequiredCheckbox"
                                        onChange={handleChange} 
                                        checked={values.TrolleyRequired} className="mt-4" />
                                    
                                    <Form.Check inline type="checkbox" label="Add Extra Blower?" id="ExtraBlowerCheckbox"
                                        name="ExtraBlower" 
                                        data-testid="ExtraBlowerCheckbox"
                                        onChange={handleChange} 
                                        checked={values.ExtraBlower} className="mt-4" />
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>

                        <Form.Group as={Col} xs="12" md="4" className="align-self-start">
                            <Form.Row>
                                <Form.Group as={Col} xs="12" className="align-self-start"> 
                                    <Form.Label>Transfer Staff</Form.Label>
                                    <Form.Control placeholder="Transfer Staff" 
                                        name="TransferStaff"                        
                                        onChange={staff => {
                                            handleChange({target: {name: "TransferStaff", value: staff.target.value.toLowerCase()}});
                                        }}
                                        value={values.TransferStaff}/>
                                </Form.Group>
                            </Form.Row>
                        </Form.Group>
                    </Form.Row>

                    <h5 className="form-section">Events</h5>
                    <Button data-testid="AddEventButton" variant="secondary" className="mb-2" onClick={addEvent}>Add Event</Button>
                    <DraggableList itemList={values.Events} itemName="Events" setValues={setValues} orderProp="EventOrder">
                        {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {Object.keys(values.Events).map((eventKey, index) => {
                                var eventItem = values.Events[eventKey];
                                return (
                                    <Draggable key={eventItem.Id} draggableId={eventItem.Id} index={index}>
                                        {(provided, snapshot) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <EventItem key={index} values={values} eventItem={eventItem} index={index} handleChange={handleChange}
                                                loadingEquipment={loadingEquipment} equipment={equipment} trailerSelected={trailerSelected} setValues={setValues}></EventItem>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                        )}
                    </DraggableList>

                    {(!loadingEquipment && Object.keys(values.Events).length > 0) &&
                        <div className="text-center mb-5">
                            <LoaderButton
                                isLoading={saving}
                                variant="primary" size="lg"
                                type="submit">Save Packing List</LoaderButton>
                        </div>}
                </React.Fragment>}
            </Form>
        </div>
    )
}
