import React from 'react';
import {  
  BrowserRouter as Router,Route,
  Switch} from 'react-router-dom';

import { PrivateRoute }  from './components/PrivateRoute';
import { Home } from './pages/Home/Home';
import { Equipment } from './pages/Equipment/Equipment';
import { EditEquipment } from './pages/Equipment/EditEquipment';
import { EditPackingList } from './pages/PackingList/EditPackingList';
import { ViewPackingList } from './pages/PackingList/ViewPackingList';
import { Vehicles } from './pages/Vehicle/Vehicles';
import { EditVehicles } from './pages/Vehicle/EditVehicles';
import { VehicleKit } from './pages/Vehicle/VehicleKit';
import { ViewTransferList } from './pages/TransferList/ViewTransferList';
import { EditTransferList } from './pages/TransferList/EditTransferList';
import { ListTransfers } from './pages/TransferList/ListTransfers';
import { Transfers } from './pages/TransferList/Transfers';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import 'bootstrap/dist/css/bootstrap.min.css';
import './scss/PackingList.scss';

import logo from './img/Monsterball.png';
import { Auth } from 'aws-amplify'

export default function PackingListRoute() {  
  return (
      <Container fluid className="col-xs-12 col-xl-9 mx-auto p-0 d-flex min-vh-100 flex-column">
        <Container fluid className="bg-white border-bottom">
          <Navbar className="navigation col-xs-12 col-md-11 col-xl-9 mx-auto px-0" expand="lg">          
            <Navbar.Brand href="/">
              <img
                alt="Packing List"
                src={logo}
                className="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbar" />
            <Navbar.Collapse id="navbar">
              <Nav className="mr-auto pl-lg-2">
                <Nav.Link href="/">Packing Lists</Nav.Link>
                <Nav.Link href="/equipment">Equipment</Nav.Link>
                <Nav.Link href="/vehicles">Vehicles</Nav.Link>
                <Nav.Link data-testid="LogoutLink" onClick={() => {
                  Auth.signOut()
                  }}>Sign Out</Nav.Link>
              </Nav>
              <Nav>
                <Button href="/packinglists/edit">Create Packing List</Button>
              </Nav>
            </Navbar.Collapse>

          </Navbar>
        </Container>
        
        <Container fluid className="bg-white pt-4 flex-fill fill d-flex">
          <div className="col-xs-12 col-md-11 col-xl-9 mx-auto px-0">
            <Switch>
              <PrivateRoute exact path="/equipment" component={Equipment} />
              <PrivateRoute path="/equipment/edit/:id?" component={EditEquipment} />
              <PrivateRoute exact path="/vehicles" component={Vehicles} />
              <PrivateRoute path="/vehicles/edit/:id?" component={EditVehicles} />
              <PrivateRoute path="/vehicles/kit/:id" component={VehicleKit} />
              <PrivateRoute exact path="/packinglists/edit/:id?" component={EditPackingList} />
              <PrivateRoute path="/packinglists/view/:id" component={ViewPackingList} />
              <PrivateRoute path="/transferlists/view/:id" component={ViewTransferList} />
              <PrivateRoute path="/transferlists/edit/:id" component={EditTransferList} />
              <PrivateRoute path="/transferlists/list/" component={ListTransfers} />
              <PrivateRoute path="/transfers/" component={Transfers} />
              <PrivateRoute path="/" component={Home} />
            </Switch>
          </div>
        </Container>
      </Container>
  );
}
