import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function EquipmentItemRow({name, label, eventId, equipmentId, values, onChange, disabled, ...props}) {
    var event = values.Events[eventId];
    var eventEquipment = event.Equipment[equipmentId];
    var controlId = uuidv4();
    return (
        <div className="form-check form-check-inline" {...props}>
            <input name={name} type="checkbox" id={name + "Checkbox" + controlId} className="form-check-input"                 
                onClick={
                    /* istanbul ignore next */
                    (event) => event.stopPropagation()}
                onChange={(event)=>onChange(event, eventId, equipmentId, name)}
                checked={eventEquipment[name]} disabled={disabled}/>
            <label data-testid="CheckboxLabel" htmlFor={name + "Checkbox" + controlId} className="form-check-label" 
                onClick={
                    /* istanbul ignore next */
                    (event) => event.stopPropagation()}
                >{label}</label>
        </div>
    );
}