import React, { useEffect } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { LoadingAlert, ErrorAlert, InfoAlert} from '../../components/alerts/Alerts';
import { useRouter } from "../../components/Router";
import { getDataByKey, saveData, deleteRecord } from '../../utils/FetchUtils';
import ViewEquipmentList from "../../components/ViewEquipmentList";

import {getCurrentUserEmail} from '../../utils/UserUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import OrderBy from 'lodash/orderBy';
import Filter from 'lodash/filter';

import moment from 'moment';

import { v4 as uuidv4 } from 'uuid';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export function ViewTransferList() {

    const vehicleData = {
        Registration:''
    }

    const initialData = {
        Id: '',
        TransferStaff: '',
        TransferDate: new Date(),
        Complete:false,

        Comments: '',

        FromVehicle: {
            ...vehicleData
        },
        ToVehicle: {
            ...vehicleData
        },
        
        TransferEquipment: [],
        UnloadEquipment: [],
        KeepEquipment: []
    }

    const [data, setData] = React.useState(initialData);
    const [editable, setEditable] = React.useState(false);
    
    const [loading, setLoading] = React.useState(false);
    const [info, setInfo] = React.useState("");
    const [error, setError] = React.useState("");
    
    const router = useRouter();
    const id = React.useRef(undefined);

    useEffect(() => {
        const fetchData = async () => {
            if (router.query.id === undefined){
                window.location.href = "/";
            }
            else {
                id.current = router.query.id;
            }
        
            setLoading(true);
            setError(null);
            setInfo(null);
    
            try {
                var responseData = await getDataByKey("TransferList", "Id", id.current);
                if (!responseData) {
                    setLoading(false);
                    setError("Unable to find a Transfer with the ID " + id.current);
                    return;
                }
                else if (responseData.isError){
                    setLoading(false);
                    setError(responseData.error);
                    return;
                }

                if (new Date(responseData.TransferDate).toISOString() < new Date().toISOString()) {
                    setEditable(false);
                } else {
                    setEditable(true);
                }
                setEditable(true);

                var orderedEquipment = OrderBy(responseData.Equipment, "EquipmentName");

                responseData.TransferEquipment = Filter(orderedEquipment, {'UnloadEquipment': false, 'KeepEquipment': false});
                responseData.UnloadEquipment = Filter(orderedEquipment, ['UnloadEquipment', true]);
                responseData.KeepEquipment = Filter(orderedEquipment, ['KeepEquipment', true]);

                setData({...initialData, ...responseData});
                setLoading(false);
                console.log(responseData);
            } catch (ex) {
                setError("Unable to load Transfer List information. The following error was returned: " + ex.message);      
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const saveTransferList = async (dataToSave) => {
        try {
            if (!editable) return;

            var saveResponse = await saveData("TransferList", "Id", {Id: data.Id, ...dataToSave});
            if (saveResponse.isError){
                setError(saveResponse.error);
                return;
            }
        } catch (ex) {
            setError("Unable to save Transfer List. The following error was returned: " + ex.message);      
        }
    };

    const markComplete = async () => {
        var uncheckedEquipment = OrderBy(Filter(data.Equipment, function(item) { return !item.selected; }), "EquipmentName");

        if (uncheckedEquipment.length > 0){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='confirmation-dialog'>
                            <h1>Unable to complete?</h1>
                            <p>The following equipment has not been checked. Make sure you have transferred all equipment before completing the Transfer List.</p>
                            <ul className="text-left">
                                {uncheckedEquipment.length > 0 && 
                                uncheckedEquipment.map((item, index) => {
                                    return (<li data-testid="UnSelectedEquipment" key={uuidv4()}>{item.EquipmentName}</li>)
                                })}
                            </ul>
                            <Button data-testid="CloseConfirm" variant="secondary" onClick={onClose}>Close</Button>
                        </div>
                    );
                }
            });
        } else {
            var user = getCurrentUserEmail();
            data.Complete = true;
            data.CompletedDate = new Date();
            saveTransferList({
                "Complete": data.Complete,
                "CompletedDate": data.CompletedDate,
                "CompletedBy": user
            });
            setData({...data});
        }
    };

    const deleteTransferList = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='delete-item'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this transfer list?</p>
                        <Button variant="secondary" onClick={onClose}>No</Button>
                        <Button data-testid="ConfirmDelete" variant="danger"
                            onClick={async() => {
                                try {
                                    var response = await deleteRecord("TransferList", data.Id, "Id");            
                                    if (response.isError) {
                                        setError(response.error);
                                    } else {
                                        setInfo("Transfer List has been deleted.");

                                        /* istanbul ignore next */
                                        setTimeout(()=>window.location.href = "/", 1000);
                                    }
                                } catch (ex) {
                                    setError("Unable to delete. The following error was returned: " + ex.message);      
                                }
                                onClose();
                            }}>Delete</Button>
                    </div>
                );
            }
        });
    };

    return (
        <div data-testid="TransferListWrapper" className={"packing-list" + (!editable ? " read-only": "")}>
            <LoadingAlert data-testid="LoadingAlert" isVisible={loading}>Loading Transfer List</LoadingAlert>
            <ErrorAlert data-testid="ErrorAlert" isVisible={error} onClose={() => setError(null)}>{error}</ErrorAlert>
            <InfoAlert data-testid="InfoAlert" isVisible={info} onClose={() => setInfo(null)}>{info}</InfoAlert>
            <InfoAlert data-testid="CompleteAlert" isVisible={data.Complete}><h4>Transfer List Complete</h4>{moment(data.CompletedDate).format("ddd DD/MM/YYYY hh:mm:ss A")}</InfoAlert>

            {!loading && 
            <React.Fragment>
            <Row className="pb-3">
                <Col xs="12" sm="6">
                    <h1>Transfer List</h1>
                    <h2>{moment(data.TransferDate).format("dddd D MMM YYYY")}</h2>
                </Col>
                <Col xs="12" sm="6" className="text-sm-right mt-2">
                    <Button data-testid="CompleteButton" variant="info" className="mr-2 mb-0" onClick={()=>markComplete()}>Mark Complete</Button>
                    {editable && <a className="btn btn-secondary mb-0 mr-2" href={"/transferlists/edit/" + data.Id}>Edit</a>}
                    <Button data-testid="DeleteTransferButton" variant="danger" onClick={()=>deleteTransferList()}>Delete</Button>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col xs="12" sm="8">
                    <Card className="packing-list-event">
                        <Card.Body>
                            <div className="d-flex transfer-list-vehicles justify-content-between align-items-center">
                                <dl className="packing-list-info important-info mb-0">
                                    <dt><h4>From Vehicle</h4></dt>
                                    <dd>{data.DisplayFromVehicleRegistration}</dd>
                                </dl>
                                {data.DisplayToVehicleRegistration &&
                                <React.Fragment>
                                    <FontAwesomeIcon icon={faArrowRight} size="3x" />
                                    <dl className="packing-list-info important-info mb-0 text-right">
                                        <dt><h4>To Vehicle</h4></dt>
                                        <dd>{data.DisplayToVehicleRegistration}</dd>
                                    </dl>
                                </React.Fragment>}
                            </div>
                        </Card.Body>
                    </Card>
                    
                </Col>
                <Col xs={{span: 12, order: 'first' }} sm={{span: 4, order: 'last' }} className="text-sm-right">
                    <dl className="packing-list-info important-info">
                        <dt><h4>Transfer Staff</h4></dt>
                        <dd>{data.DisplayTransferStaff}</dd>
                    </dl>
                    {data.Comments &&
                    <dl className="packing-list-info important-info">
                        <dt><h4>Comments</h4></dt>
                        <dd style={{whiteSpace: 'pre-wrap'}}>{data.Comments}</dd>
                    </dl>}
                </Col>
            </Row>
            <Row>
                {data.TransferEquipment.length > 0 &&
                <Col xs="12">
                    <h5 className="equipment-section">Equipment to Transfer</h5>
                    <Card className="packing-list-event mb-2 border-0">
                        <table className='table table-striped table-bordered equipment-table m-0'>
                            <tbody data-testid="TransferEquipment">
                                <ViewEquipmentList data={data} equipmentList={data.TransferEquipment} selector="Equipment"
                                    savePackingList={saveTransferList} setData={setData} nameKey="EquipmentName"/>
                            </tbody>
                        </table>
                    </Card>
                </Col>}
            </Row>
            <Row>
                {data.UnloadEquipment.length > 0 &&
                <Col xs="12">
                    <h5 className="equipment-section">Equipment to Unload</h5>
                    <Card className="packing-list-event mb-2 border-0">
                        <table className='table table-striped table-bordered equipment-table m-0'>
                            <tbody data-testid="UnloadEquipment">    
                                <ViewEquipmentList data={data} equipmentList={data.UnloadEquipment} selector="Equipment"
                                    savePackingList={saveTransferList} setData={setData} nameKey="EquipmentName"/>
                            </tbody>
                        </table>
                    </Card>
                </Col>}
            </Row>
            <Row>
                {data.KeepEquipment.length > 0 &&
                <Col xs="12">
                    <h5 className="equipment-section">Equipment to Keep in Vehicle</h5>
                    <Card className="packing-list-event mb-2 border-0">
                        <table className='table table-striped table-bordered equipment-table m-0'>
                            <tbody data-testid="KeepEquipment">    
                                <ViewEquipmentList data={data} equipmentList={data.KeepEquipment} selector="Equipment"
                                    savePackingList={saveTransferList} setData={setData} nameKey="EquipmentName"/>
                            </tbody>
                        </table>
                    </Card>
                </Col>}
            </Row>
            </React.Fragment>}
        </div>     
    )
}
